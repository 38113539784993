import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import * as XLSX from "xlsx";
import { Pagination } from "./Pagination";

export const UsersSection = () => {
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allCustomersData, setallCustomersData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");
  const callCountLimit = 10;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: "",
    limit: callCountLimit,
    skip: 0,
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    dataShare.getListofCustomers(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        setallCustomersData([]);
      } else {
        setallCustomersData(data.allDBCustomers);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter]);

  const downloadExcel = () => {
    dataShare.getListofCustomersForDownload().then((res) => {
      if (res.rescode) {

        let data = res.data;
        // console.log(data)

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

        let d = new Date();
        let date = dataShare.formatDate(d);
        let time = dataShare.formatTime(d);
        let fileName = `All Customers ${date}-${time}.xlsx`;
        // Triggering the download
        XLSX.writeFile(workbook, fileName);
      }else{
        dataShare.showNewTost("Something Went Wrong", "error  ")
      }
    });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Customers</li>
          </ol>
        </nav>
        <div className="mb-9">
          <div className="row g-2 mb-4">
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-0">Customers</h2>
              <div className="d-flex mb-0">
                <button
                  className="btn btn-phoenix-primary me-2 px-6"
                  onClick={downloadExcel}
                >
                  Export All &nbsp;
                  <span className="fas fa-file-export"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <input
                  className="form-control search-input search"
                  type="search"
                  placeholder="Search Customer"
                  aria-label="Search"
                  value={callDataFilter.filter}
                  onChange={(e) => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filter: e.target.value,
                      skip: 0,
                    });
                    setcallCountData({
                      ...callCountData,
                      activePage: 0,
                    });
                  }}
                />
                <span className="fas fa-search search-box-icon" />
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs--1 mb-0">
                <thead>
                  <tr>
                    <th
                      className="white-space-nowrap fs--1 align-middle ps-0"
                      style={{ width: "5%" }}
                    >
                      <div className="form-check mb-0 fs-0">&nbsp;</div>
                    </th>
                    <th
                      className="sort align-middle pe-5"
                      scope="col"
                      data-sort="customer"
                      style={{ width: "20%" }}
                    >
                      CUSTOMER
                    </th>
                    <th
                      className="sort align-middle pe-5"
                      scope="col"
                      data-sort="email"
                      style={{ width: "25%" }}
                    >
                      EMAIL
                    </th>
                    <th
                      className="sort align-middle pe-5"
                      scope="col"
                      data-sort="email"
                      style={{ width: "25%" }}
                    >
                      Phone Number
                    </th>
                    <th
                      className="sort align-middle text-end"
                      scope="col"
                      data-sort="total-orders"
                      style={{ width: "10%" }}
                    >
                      ORDERS
                    </th>
                    <th
                      className="sort align-middle text-end ps-3"
                      scope="col"
                      data-sort="total-spent"
                      style={{ width: "10%" }}
                    >
                      TOTAL SPENT
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {allCustomersData.map(function (u, idx) {
                    return (
                      <tr
                        className="hover-actions-trigger btn-reveal-trigger position-static"
                        key={idx}
                      >
                        <td className="fs--1 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-0">{idx + 1}</div>
                        </td>
                        <td className="customer align-middle white-space-nowrap pe-5">
                          <Link
                            className="d-flex align-items-center"
                            to={u._id}
                          >
                            <div className="avatar avatar-m">
                              <img
                                className="rounded-circle"
                                src={
                                  u.photoURL ||
                                  process.env.REACT_APP_USER_PROFILE
                                }
                                alt=""
                              />
                            </div>
                            <p className="mb-0 ms-3 text-1100 fw-bold">
                              {u.fname} {u.lname}
                            </p>
                          </Link>
                        </td>
                        <td className="email align-middle white-space-nowrap pe-5">
                          <Link className="fw-semi-bold" to="#">
                            {u.email}
                          </Link>
                        </td>
                        <td className="email align-middle white-space-nowrap pe-5">
                          <Link className="fw-semi-bold" to="#">
                            {u.phone}
                          </Link>
                        </td>
                        <td className="total-orders align-middle white-space-nowrap fw-semi-bold text-end text-1000">
                          {u.orderCount}
                        </td>
                        <td className="total-spent align-middle white-space-nowrap fw-bold text-end ps-3 text-1100">
                          ₹{dataShare.formatAmount(u.totalSpend)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Pagination 
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
              />
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        dataShare.onComponentLoad();
        if (userData.accessAllowed.admin || userData.accessAllowed.crmManager) {
        } else {
          dataShare.showNewTost("Invalid access for CRM Management.", "error");
          navigate("/dash");
        }
      }, [])}
    </>
  );
};
