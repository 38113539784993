import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import { useParams } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { AccessDeniedEmp } from "./AccessDeniedEmp";

export const EditEmp = () => {
  const { dataShare, userData } = useContext(ServiceContext);
  let { empid } = useParams();
  const [allUsersData, setallUsersData] = useState();
  
  // const [callDataFilter, setcallDataFilter] = useState()
  useEffect(() => {
    let empDataFilter = {
      filter: empid,
      limit: 1,
      skip: 0
    }

    dataShare.getListofEmps(empDataFilter).then((data) => {
      setallUsersData(data.allDBUsers[0]);
    });
  }, [empid]);


  

  const updateAccess = () => {
    let formData = {
      empid: allUsersData._id,
      accessAllowed: allUsersData.accessAllowed,
      accessType: allUsersData.accessType,
      emailverified: allUsersData.emailverified,
    };
    dataShare.updateAccess(formData).then((data)=>{
      dataShare.showNewTost("Data updated", "success")
      setallUsersData(data)
    });
  };


  const EmpEditCom = () =>{
    return (<>
      <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <div className="row">
          <div className="col-xl-9">
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">Update Employee Access</h2>
              <div className="d-flex mb-3">
                <Link to="/dash/employees">
                  <button className="btn btn-phoenix-primary me-2 px-6">
                    Back
                  </button>
                </Link>
                {/* <button className="btn btn-primary"> Save </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-9">
          <div className="d-flex align-items-end position-relative mb-7">
            <div className="hoverbox" style={{ width: 150, height: 150 }}>
              <div className="position-relative rounded-circle cursor-pointer d-flex flex-center mb-xxl-7">
                <div className="avatar avatar-5xl">
                  <img
                    className="rounded-circle"
                    src={
                      allUsersData.photoURL ||
                      process.env.REACT_APP_USER_PROFILE
                    }
                    alt=""
                  />
                </div>
                <label
                  className="w-100 h-100 position-absolute z-index-1"
                  htmlFor="upload-avatar"
                />
              </div>
            </div>
          </div>
          <h4 className="mb-3">Basic Information </h4>
          <div className="row g-3 mb-9">
            <div className="col-sm-6 col-md-4">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="floatingInputFirstname"
                  type="text"
                  placeholder="First name"
                  value={allUsersData.fname}
                  readOnly={true}
                  disabled={true}
                />
                <label htmlFor="floatingInputFirstname">First name</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="floatingInputLastname"
                  type="text"
                  placeholder="Last name"
                  value={allUsersData.lname}
                  readOnly={true}
                  disabled={true}
                />
                <label htmlFor="floatingInputLastname">Last name</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="floatingInputEmail"
                  type="text"
                  placeholder="Email"
                  value={allUsersData.email}
                  readOnly={true}
                  disabled={true}
                />
                <label htmlFor="floatingInputEmail">Email</label>
              </div>
            </div>

            <h4 className="mt-6">Manage Access</h4>
            <div className="col-sm-6 col-md-4">
              <div className="form-check form-check-inline col-sm-12">
                <input
                  className="form-check-input"
                  id="empM"
                  type="checkbox"
                  defaultChecked={
                    allUsersData.accessAllowed.employeeManager
                  }
                  onChange={(e) => {
                    setallUsersData({
                      ...allUsersData,
                      accessAllowed: {
                        ...allUsersData.accessAllowed,
                        employeeManager:
                          !allUsersData.accessAllowed.employeeManager,
                      },
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="empM">
                  Employees
                </label>
              </div>
              <div className="form-check form-check-inline col-sm-12">
                <input
                  className="form-check-input"
                  id="prdM"
                  type="checkbox"
                  defaultChecked={
                    allUsersData.accessAllowed.productManager
                  }
                  onChange={(e) => {
                    setallUsersData({
                      ...allUsersData,
                      accessAllowed: {
                        ...allUsersData.accessAllowed,
                        productManager:
                          !allUsersData.accessAllowed.productManager,
                      },
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="prdM">
                  Products
                </label>
              </div>
              <div className="form-check form-check-inline col-sm-12">
                <input
                  className="form-check-input"
                  id="crmM"
                  type="checkbox"
                  defaultChecked={allUsersData.accessAllowed.crmManager}
                  onChange={(e) => {
                    setallUsersData({
                      ...allUsersData,
                      accessAllowed: {
                        ...allUsersData.accessAllowed,
                        crmManager:
                          !allUsersData.accessAllowed.crmManager,
                      },
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="crmM">
                  CRM
                </label>
              </div>
            </div>

            <div className="col-sm-6 col-md-4">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  id="accessDash"
                  type="checkbox"
                  defaultChecked={allUsersData.accessType == "employee"}
                  onChange={(e) => {
                    setallUsersData({
                      ...allUsersData,
                      accessType:
                        allUsersData.accessType == "employee"
                          ? "blocked"
                          : "employee",
                    });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="accessDash"
                >
                  Access of Dashboard
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  id="emailVerifiedDash"
                  type="checkbox"
                  defaultChecked={allUsersData.emailverified}
                  onChange={(e) => {
                    setallUsersData({
                      ...allUsersData,
                      emailverified: !allUsersData.emailverified,
                    });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="emailVerifiedDash"
                >
                  Email Verified
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4"></div>
        </div>
      </div>
      <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <div className="row">
          <div className="col-xl-9">
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">&nbsp;</h2>
              <div className="d-flex mb-3">
                <Link to="/dash/employees">
                  <button className="btn btn-phoenix-primary me-2 px-6">
                    Cancel
                  </button>
                </Link>
                <button
                  className="btn btn-primary px-6"
                  onClick={updateAccess}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }


  return userData.accessAllowed.admin ||
    (userData.accessAllowed.employeeManager && allUsersData?._id != userData.id && !allUsersData?.accessAllowed?.admin)  ? (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dash/employees">Employees</Link>
            </li>
            <li className="breadcrumb-item active">Update Employee</li>
          </ol>
        </nav>

        {allUsersData ? (
          <EmpEditCom/>
        ) : (
          <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
            <div className="row">
              <div className="col-xl-9">
                <div className="d-sm-flex justify-content-between">
                  <h2 className="mb-4">Invalid URL</h2>
                </div>
              </div>
            </div>
          </div>
        )}
      

        

        <DashFooter />
      </div>
    </>
  ) : (
    <AccessDeniedEmp />
  );
};
