import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import { CusomerInnerComps } from "./CusomerInnerComps";
import { CustomerInnerWishlist } from "./CustomerInnerWishlist";
import { CustomerInnterCart } from "./CustomerInnterCart";

export const CustomerDefails = () => {
  const { dataShare, userData } = useContext(ServiceContext);
  const navigate = useNavigate();
  let { userid } = useParams();

  const [useDataDetail, setuseDataDetail] = useState();

  const [usedCoupons, setusedCoupons] = useState([]);
  const [ownedCoupons, setownedCoupons] = useState([]);

  useEffect(() => {
    dataShare.getCustomerDetails(userid).then((data) => {
      // console.log(data)
      if (data.rescode) {
        setusedCoupons([]);
        setownedCoupons([])
        setuseDataDetail(data);
        console.log(data);
        if (data.coustomerData.usedCupons){
          setusedCoupons([...data.coustomerData.usedCupons]);
        }
        if(data.coustomerData.myOwnedCoupons){
          setownedCoupons([...data.coustomerData.myOwnedCoupons])
        }
          
      } else {
        console.log("Invalid User ID");
      }
    });
  }, []);
  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dash/users">Customers</Link>
            </li>
            <li className="breadcrumb-item active">Customer details</li>
          </ol>
        </nav>
        {useDataDetail ? (
          <div className="mb-9">
            <div className="row align-items-center justify-content-between g-3 mb-4">
              <div className="col-auto">
                <h2 className="mb-0">Customer details</h2>
              </div>
              <div className="col-auto">
                <div className="row g-3">
                  {/* <div className="col-auto">
                    <button className="btn btn-phoenix-danger">
                      <span className="fa-solid fa-trash-can me-2" />
                      Delete customer
                    </button>
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-phoenix-secondary">
                      <span className="fas fa-key me-2" />
                      Reset password
                    </button>
                  </div> */}
                  {/* <div className="col-auto">
                    <Link to="/dash/users">
                      {" "}
                      <button className="btn btn-phoenix-secondary">
                        Go Back
                      </button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-12 col-xxl-4">
                <div className="row g-3 g-xxl-0 h-100">
                  <div className="col-12 col-md-7 col-xxl-12 mb-xxl-3">
                    <div
                      className="card"
                      style={{ position: "sticky", top: "70px" }}
                    >
                      <div className="card-body d-flex flex-column justify-content-between pb-3">
                        <div className="row align-items-center g-5 mb-3 text-center text-sm-start">
                          <div className="col-12 col-sm-12 mb-sm-2">
                            <div className="avatar avatar-5xl">
                              <img
                                className="rounded-circle"
                                src={
                                  useDataDetail.coustomerData.photoURL ||
                                  process.env.REACT_APP_USER_PROFILE
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 flex-1">
                            <h3>
                              {useDataDetail.coustomerData.fname}{" "}
                              {useDataDetail.coustomerData.lname}
                            </h3>
                            <br />

                            <div className="mb-3">
                              <h5 className="text-800">Joined On</h5>
                              {dataShare.printDateBasedOnTP(
                                useDataDetail.coustomerData.timestamp
                              )}
                            </div>
                            <div className="mb-3">
                              <h5 className="text-800">Email</h5>
                              <a
                                href={
                                  "mailto:" + useDataDetail.coustomerData.email
                                }
                              >
                                {useDataDetail.coustomerData.email}
                              </a>
                            </div>
                            {useDataDetail.coustomerData.phone ? (
                              <div className="mb-3">
                                <h5 className="text-800">Phone</h5>
                                <a
                                  href={
                                    "tel:" + useDataDetail.coustomerData.phone
                                  }
                                >
                                  {useDataDetail.coustomerData.phone || "N/A"}
                                </a>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-between-center border-top border-dashed border-300 pt-4">
                          <div>
                            <h6>Orders Count</h6>
                            <p className="fs-1 text-800 mb-0">
                              {useDataDetail.cartOrdersData}
                            </p>
                          </div>
                          <div>
                            <h6>Wishlist Count</h6>
                            <p className="fs-1 text-800 mb-0">
                              {useDataDetail.wishListData}
                            </p>
                          </div>
                          <div>
                            <h6>Cart Count</h6>
                            <p className="fs-1 text-800 mb-0">
                              {useDataDetail.cartListData}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-xl-12 mt-0">
                    <div className="card">
                      <div className="card-body pb-3">
                        <div className="mb-3">
                          <h5 className="mb-2">
                            Used Coupons{" "}
                            <span className="text-700 fw-normal">
                              ({usedCoupons.length})
                            </span>
                          </h5>

                          {usedCoupons.map((cp, key) => {
                            return (
                              <span
                                className="badge bg-success fs--2 product-verified-badge tags_p"
                                key={key}
                              >
                                {cp}
                                <button
                                  className="tags_cb"
                                  onClick={() => {
                                    const index = usedCoupons.indexOf(cp);
                                    let newArr = [...usedCoupons];
                                    if (index > -1) {
                                      // only splice array when item is found
                                      newArr.splice(index, 1); // 2nd parameter means remove one item only
                                    }
                                    setusedCoupons(newArr);
                                  }}
                                >
                                  <svg
                                    className="svg-inline--fa fa-xmark tags_ci"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="xmark"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                                    />
                                  </svg>
                                  {/* <i class="fa fa-close tags_ci"></i> Font Awesome fontawesome.com */}
                                </button>
                              </span>
                            );
                          })}
                        </div>
                        <button
                          className="btn btn-primary mb-2 mb-sm-0"
                          type="button"
                          onClick={() => {
                            dataShare
                              .updateCustomerCouponData({
                                couponType: "used",
                                newData: usedCoupons,
                                uid: useDataDetail.coustomerData._id,
                              })
                              .then((data) => {
                                console.log(data);
                              });
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-xl-12 mt-0">
                    <div className="card">
                      <div className="card-body pb-3">
                        <div className="mb-3">
                          <h5 className="mb-2">
                            Owned Coupons{" "}
                            <span className="text-700 fw-normal">
                              ({ownedCoupons.length})
                            </span>
                          </h5>

                          {ownedCoupons.map((cp, key) => {
                            return (
                              <span
                                className="badge bg-success fs--2 product-verified-badge tags_p"
                                key={key}
                              >
                                {cp}
                                <button
                                  className="tags_cb"
                                  onClick={() => {
                                    const index = ownedCoupons.indexOf(cp);
                                    let newArr = [...ownedCoupons];
                                    if (index > -1) {
                                      // only splice array when item is found
                                      newArr.splice(index, 1); // 2nd parameter means remove one item only
                                    }
                                    setownedCoupons(newArr);
                                  }}
                                >
                                  <svg
                                    className="svg-inline--fa fa-xmark tags_ci"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="xmark"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                                    />
                                  </svg>
                                  {/* <i class="fa fa-close tags_ci"></i> Font Awesome fontawesome.com */}
                                </button>
                              </span>
                            );
                          })}
                        </div>
                        <button
                          className="btn btn-primary mb-2 mb-sm-0"
                          type="button"
                          onClick={() => {
                            dataShare
                              .updateCustomerCouponData({
                                couponType: "owned",
                                newData: ownedCoupons,
                                uid: useDataDetail.coustomerData._id,
                              })
                              .then((data) => {
                                console.log(data);
                              });
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xxl-8">
                <div className="mb-6">
                  <h3 className="mb-4">
                    Orders{" "}
                    <span className="text-700 fw-normal">
                      ({useDataDetail.cartOrdersData})
                    </span>
                  </h3>
                  <CusomerInnerComps uid={userid} />
                </div>
                <div className="mb-6">
                  <h3 className="mb-4">
                    Wishlist{" "}
                    <span className="text-700 fw-normal">
                      ({useDataDetail.wishListData})
                    </span>
                  </h3>
                  <CustomerInnerWishlist uid={userid} />
                </div>
                <div className="mb-6">
                  <h3 className="mb-4">
                    CartList{" "}
                    <span className="text-700 fw-normal">
                      ({useDataDetail.cartListData})
                    </span>
                  </h3>
                  <CustomerInnterCart uid={userid} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row align-items-center justify-content-between g-3 mb-4">
              <div className="col-auto">
                <h2 className="mb-0">Customer details not found</h2>
              </div>
            </div>
          </>
        )}

        <DashFooter />
      </div>

      {useEffect(() => {
        dataShare.onComponentLoad();
        if (userData.accessAllowed.admin || userData.accessAllowed.crmManager) {
        } else {
          dataShare.showNewTost("Invalid access for CRM Management.", "error");
          navigate("/dash");
        }
      }, [])}
    </>
  );
};
