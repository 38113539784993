import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ServiceContext from "../services/ServiceContext";
import { MainLogo } from "../dash/InnerComps/Logos/MainLogo";
export const ResetPassAskEmail = () => {
  const [emailField, setemailField] = useState("");
  const { dataShare, settopBar } = useContext(ServiceContext);
  const [disbaleCTA, setdisbaleCTA] = useState(false);

  const askForResetWithEmail = () => {
    const regEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    let formIsValid = true;
    let invalidFields = [];
    if (!regEmail.test(emailField)) {
      formIsValid = false;
      invalidFields.push("askemail");
    }
    if (formIsValid) {
      setdisbaleCTA(true);
      settopBar(true);
      dataShare.askForResetPass(emailField).then(() => {
        settopBar(false);
        setdisbaleCTA(false);
      });
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      askForResetWithEmail();
    }
  };
  return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-10 col-md-8 col-lg-5 col-xxl-4">
                <Link
                  className="d-flex flex-center text-decoration-none mb-4"
                  to="/"
                >
                  <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                    <MainLogo type={"main"} customWidth={"150"} />
                  </div>
                </Link>
                <div className="px-xxl-5">
                  <div className="text-center mb-6">
                    <h4 className="text-1000">Forgot your password?</h4>
                    <p className="text-700 mb-5">
                      Enter your email below and we will send{" "}
                      <br className="d-sm-none" />
                      you a reset link
                    </p>
                    <div className="d-flex align-items-center mb-5">
                      <input
                        className="form-control flex-1 formField"
                        id="askemail"
                        type="email"
                        placeholder="Email"
                        value={emailField}
                        onChange={(e) => {
                          setemailField(e.target.value);
                          e.target.classList.remove("is-invalid");
                        }}
                        onKeyDown={handleKeyDown}
                      />

                      {disbaleCTA ? (
                        <button
                          className="btn btn-primary ms-2"
                          disabled={disbaleCTA}
                          onClick={askForResetWithEmail}
                        >
                          Sending &nbsp;
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ width: 15, height: 15 }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary ms-2"
                          disabled={disbaleCTA}
                          onClick={askForResetWithEmail}
                        >
                          Send
                          <svg
                            className="svg-inline--fa fa-chevron-right ms-2"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <Link className="fs--1 fw-bold" to="/login">
                      Go to login page.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
