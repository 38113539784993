import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import { AccessDeniedEmp } from "./AccessDeniedEmp";

export const AddNewEmp = () => {
  const { dataShare, userData } = useContext(ServiceContext);

  const currentUserData = {
    fname: "",
    lname: "",
    email: "",
    accessAllowed: {
      admin: false,
      productManager: false,
      employeeManager: false,
      crmManager: false,
    },
    accessType: "employee",
    emailverified: false,
  };

  const [newUserData, setnewUserData] = useState(currentUserData);

  const createEmp = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    const regEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    let formIsValid = true;
    let invalidFields = [];

    if (newUserData.fname.length < 2) {
      formIsValid = false;
      invalidFields.push("floatingInputFirstname");
    }
    if (newUserData.lname.length < 2) {
      formIsValid = false;
      invalidFields.push("floatingInputLastname");
    }
    if (!regEmail.test(newUserData.email)) {
      formIsValid = false;
      invalidFields.push("floatingInputEmail");
    }

    if (formIsValid) {
      dataShare.createEmp(newUserData).then((data)=>{
        if(data.rescode === 1){
          setnewUserData(currentUserData)
          dataShare.showNewTost("Employee Added", "info")
        }else {
          dataShare.showNewTost("Employee with same email already exist", "warning")
        }
        
      })
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  
  return userData.accessAllowed.admin ||
  userData.accessAllowed.employeeManager ? (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dash/employees">Employees</Link>
            </li>
            <li className="breadcrumb-item active">Add Employee</li>
          </ol>
        </nav>
        <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
          <div className="row">
            <div className="col-xl-9">
              <div className="d-sm-flex justify-content-between">
                <h2 className="mb-4">Add new employee</h2>
                <div className="d-flex mb-3">
                  <Link to="/dash/employees">
                    <button className="btn btn-phoenix-primary me-2 px-6">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-9">
            <h4 className="mb-3">Employee Information </h4>
            <div className="row g-3 mb-9">
              <div className="col-sm-6 col-md-4">
                <div className="form-floating">
                  <input
                    className="form-control formField"
                    id="floatingInputFirstname"
                    type="text"
                    placeholder="First name"
                    value={newUserData.fname}
                    onChange={(e) => {
                      setnewUserData({ ...newUserData, fname: e.target.value });
                      e.target.classList.remove("is-invalid");
                    }}
                  />
                  <label htmlFor="floatingInputFirstname">First name</label>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-floating">
                  <input
                    className="form-control formField"
                    id="floatingInputLastname"
                    type="text"
                    placeholder="Last name"
                    value={newUserData.lname}
                    onChange={(e) => {
                      setnewUserData({ ...newUserData, lname: e.target.value });
                      e.target.classList.remove("is-invalid");
                    }}
                  />
                  <label htmlFor="floatingInputLastname">Last name</label>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-floating">
                  <input
                    className="form-control formField"
                    id="floatingInputEmail"
                    type="text"
                    placeholder="email"
                    value={newUserData.email}
                    onChange={(e) => {
                      setnewUserData({ ...newUserData, email: e.target.value });
                      e.target.classList.remove("is-invalid");
                    }}
                  />
                  <label htmlFor="floatingInputEmail">Email</label>
                </div>
              </div>
              <h4 className="mt-6">Manage Access</h4>
              <div className="col-sm-6 col-md-4">
                <div className="form-check form-check-inline col-sm-12">
                  <input
                    className="form-check-input"
                    id="empM"
                    type="checkbox"
                    defaultChecked={newUserData.accessAllowed.employeeManager}
                    onChange={(e) => {
                      setnewUserData({
                        ...newUserData,
                        accessAllowed: {
                          ...newUserData.accessAllowed,
                          employeeManager:
                            !newUserData.accessAllowed.employeeManager,
                        },
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="empM">
                    Employees
                  </label>
                </div>
                <div className="form-check form-check-inline col-sm-12">
                  <input
                    className="form-check-input"
                    id="prdM"
                    type="checkbox"
                    defaultChecked={newUserData.accessAllowed.productManager}
                    onChange={(e) => {
                      setnewUserData({
                        ...newUserData,
                        accessAllowed: {
                          ...newUserData.accessAllowed,
                          productManager:
                            !newUserData.accessAllowed.productManager,
                        },
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="prdM">
                    Products
                  </label>
                </div>
                <div className="form-check form-check-inline col-sm-12">
                  <input
                    className="form-check-input"
                    id="crmM"
                    type="checkbox"
                    defaultChecked={newUserData.accessAllowed.crmManager}
                    onChange={(e) => {
                      setnewUserData({
                        ...newUserData,
                        accessAllowed: {
                          ...newUserData.accessAllowed,
                          crmManager: !newUserData.accessAllowed.crmManager,
                        },
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="crmM">
                    CRM
                  </label>
                </div>
              </div>

              <div className="col-sm-6 col-md-4">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="accessDash"
                    type="checkbox"
                    defaultChecked={newUserData.accessType == "employee"}
                    onChange={(e) => {
                      setnewUserData({
                        ...newUserData,
                        accessType:
                          newUserData.accessType == "employee"
                            ? "blocked"
                            : "employee",
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="accessDash">
                    Access of Dashboard
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="emailVerifiedDash"
                    type="checkbox"
                    defaultChecked={newUserData.emailverified}
                    onChange={(e) => {
                      setnewUserData({
                        ...newUserData,
                        emailverified: !newUserData.emailverified,
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="emailVerifiedDash"
                  >
                    Email Verified
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
          <div className="row">
            <div className="col-xl-9">
              <div className="d-sm-flex justify-content-between">
                <h2 className="mb-4">&nbsp;</h2>
                <div className="d-flex mb-3">
                  <Link to="/dash/employees">
                    <button className="btn btn-phoenix-primary me-2 px-6">
                      Cancel
                    </button>
                  </Link>
                  <button className="btn btn-primary px-6" onClick={createEmp}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>
    </>
  ) : (
    <AccessDeniedEmp />
  );
};
