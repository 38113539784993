import React, { memo, useContext, useEffect, useMemo, useState } from "react";
// import { dataContext } from "../../../App";
import { DashFooter } from "./CommonSections/DashFooter";
import ServiceContext from "../../services/ServiceContext";
import { SevenDaysGraph } from "./Homegraphs/SevenDaysGraph";
import { MothSpecificGraph } from "./Homegraphs/MothSpecificGraph";
import { TotalSolds } from "./Homegraphs/TotalSolds";

export const DashHome = () => {
  const { dataShare } = useContext(ServiceContext);
  // https://echarts.apache.org/examples/en/editor.html?c=dataset-encode0&theme=dark

  // ss


  const [mfilter, setmfilter] = useState({});

  const [dashData, setdashData] = useState({ loaded: false });

  function getCurrentDateInFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  function getCurrentDateInFormat2() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    return `${year}-${month}}`;
  }

  let fullDate = getCurrentDateInFormat();
  let yearMonth = getCurrentDateInFormat2();
  let cFilter = {
    section1Data_TotalOrders: fullDate,
    totalOrdersGraph: fullDate,
  };

  const mapDashAfterRes = async (data) => {
    // console.log(data);
    data = data.data;
    data.loaded = true;
    setdashData(data);
  };

  useEffect(() => {
    dataShare.getHomeDash(cFilter).then((data) => {
      mapDashAfterRes(data);
    });
  }, []);

  return (
    <>
      {dashData.loaded ? (
        <div className="content">
          <div className="pb-5">
            <div className="row g-4">
              <div className="col-12 col-xxl-6">
                <div className="mb-8">
                  <h2 className="mb-2">Ecommerce Dashboard </h2>
                  <h5 className="text-700 fw-semi-bold">
                    Here’s what’s going on at your business right now
                  </h5>
                </div>
                <div className="row align-items-center g-4">
                  <div className="col-12 col-md-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="assets/img/icons/illustrations/4l.png"
                        alt=""
                        height={46}
                        width={46}
                      />
                      <div className="ms-3">
                        <h4 className="mb-0">
                          {dashData.section1Data_TotalOrders} new orders
                        </h4>
                        <p className="text-800 fs--1 mb-0">In last 7 Days</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="assets/img/icons/illustrations/3l.png"
                        alt=""
                        height={46}
                        width={46}
                      />
                      <div className="ms-3">
                        <h4 className="mb-0">
                          {dashData.section2Data_TotalHoldOrders} orders
                        </h4>
                        <p className="text-800 fs--1 mb-0">
                          Currently In4Process
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="assets/img/icons/illustrations/2l.png"
                        alt=""
                        height={46}
                        width={46}
                      />
                      <div className="ms-3">
                        <h4 className="mb-0">
                          {dashData.section3Data_OutOfStocks} products
                        </h4>
                        <p className="text-800 fs--1 mb-0">Out of stock</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-200 mb-6 mt-4" />
              </div>
              <div className="col-12 col-xxl-6">
                <div className="row g-3">
                  <div className="col-12 col-md-12">
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="mb-1">
                              Total orders / customers
                              <span className="badge badge-phoenix badge-phoenix-warning rounded-pill fs--1 ms-2">
                                {/* <span className="badge-label">-6.8%</span> */}
                              </span>
                            </h5>
                            <h6 className="text-700">Last 7 days</h6>
                          </div>
                          {/* <h4>{dashData.section1Data_TotalOrders}</h4> */}
                        </div>
                        <div className="d-flex justify-content-center px-0 py-1">
                          <SevenDaysGraph
                            data={dashData.totalOrdersGraph}
                            data2={dashData.weekSpecificCustomers}
                            className=""
                            // style={{width: '100%' }}
                          />
                        </div>
                        <div className="mt-2">
                          <div className="d-flex align-items-center mb-2">
                            <div className="bullet-item bg-primary me-2"></div>
                            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
                              Orders
                            </h6>
                            {/* <h6 className="text-900 fw-semi-bold mb-0">52%</h6> */}
                          </div>
                          <div className="d-flex align-items-center">
                            <div
                              className="bullet-item  me-2"
                              style={{ background: "#91cc75" }}
                            ></div>
                            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
                              Customers
                            </h6>
                            {/* <h6 className="text-900 fw-semi-bold mb-0">48%</h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-6 pb-9 border-top border-300">
            <div className="row g-6">
              <div className="col-12 col-xl-6">
                <div className="me-xl-4">
                  <div>
                    <h3>Total new orders</h3>
                    <p className="mb-1 text-700">In last 30 Days</p>
                  </div>
                  <MothSpecificGraph
                    data={dashData.MonthSpecificOrders}
                    lable="Orders"
                    className=""
                    // style={{width: '100%' }}
                  />
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div>
                  <h3>Total new customers</h3>
                  <p className="mb-1 text-700">In last 30 Days</p>
                </div>
                <MothSpecificGraph
                  data={dashData.MonthSpecificCustomers}
                  lable="Customer"
                  className=""
                  // style={{width: '100%' }}
                />
              </div>
            </div>
            <div className="row g-6">
              <div className="col-12 col-xl-12">
                <div>
                  <h3>Top 15 Products</h3>
                  <p className="mb-1 text-700">Top 15 max sold prodcuts</p>
                </div>
                <TotalSolds data={dashData.top10Products} className="" style={{width: "100%"}} />
              </div>
            </div>
          </div>
          <DashFooter />
        </div>
      ) : (
        <></>
      )}

      {useEffect(() => {
        dataShare.onComponentLoad();
      }, [])}
    </>
  );
};
