import React from "react";

export const PaymentStatusCondition = ({ status }) => {
  return (
    <>
      {
        {
          paid: (
            <span className="badge badge-phoenix fs--2 badge-phoenix-success">
              <span className="badge-label">Paid</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-check ms-1"
                style={{
                  height: "12.8px",
                  width: "12.8px",
                }}
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            </span>
          ),
          refund: (
            <span className="badge badge-phoenix fs--8 badge-phoenix-success">
              <span className="badge-label">Refunded </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-info ms-1"
                style={{
                  height: "12.8px",
                  width: "12.8px",
                }}
              >
                <circle cx={12} cy={12} r={10} />
                <line x1={12} y1={16} x2={12} y2={12} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
              </svg>
            </span>
          ),
          canceled: (
            <span className="badge badge-phoenix fs--2 badge-phoenix-secondary">
              <span className="badge-label">Cancelled</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x ms-1"
                style={{
                  height: "12.8px",
                  width: "12.8px",
                }}
              >
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </span>
          ),
          rejected: (
            <span className="badge badge-phoenix fs--2 badge-phoenix-danger">
              <span className="badge-label">Failed</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x ms-1"
                style={{
                  height: "12.8px",
                  width: "12.8px",
                }}
              >
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </span>
          ),
          pendding: (
            <span className="badge badge-phoenix fs--2 badge-phoenix-warning">
              <span className="badge-label">Pending</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-info ms-1"
                style={{
                  height: "12.8px",
                  width: "12.8px",
                }}
              >
                <circle cx={12} cy={12} r={10} />
                <line x1={12} y1={16} x2={12} y2={12} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
              </svg>
            </span>
          ),
        }[status]
      }
    </>
  );
};
