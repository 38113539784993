import React from "react";

export const TopLoadingBar = () => {
  return (
    <>
      <div className="top-loading">
        <div></div>
      </div>
    </>
  );
};
