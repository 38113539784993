import React, { useContext, useEffect, useState } from "react";
// import $ from 'jquery';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { DashFooter } from "../CommonSections/DashFooter";

export const Coupon = () => {
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allCoupons, setallCoupons] = useState([]);
  const [newCoupon, setnewCoupon] = useState("");
  const [searchFilter, setsearchFilter] = useState("");
  const [selectPecent, setselectPecent] = useState("05");

  const addNewCoupon = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });
    let formIsValid = true;
    let invalidFields = [];

    if (newCoupon.length < 2) {
      formIsValid = false;
      invalidFields.push("brdName");
    }

    if (formIsValid) {
      const FD = new FormData();
      let couponData = {
        couponName: newCoupon,
        couponAddedBy: userData.id,
      };
      FD.append("couponData", JSON.stringify(couponData));
      dataShare.addNewCoupon(FD).then((data) => {
        if (data.rescode == 0 || data.rescode == 3) {
          dataShare.showNewTost(
            "Coupon Code with same name already exist",
            "error"
          );
        } else {
          setallCoupons(data.couponData);
          dataShare.showNewTost("Coupon Added", "success");
          setnewCoupon(randomCoupon + selectPecent);
        }
      });
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };
  function generateCouponCode() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let couponCode = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * alphabet.length);
      couponCode += alphabet.charAt(randomIndex);
    }
    return couponCode;
  }

  // Example usage:
  const randomCoupon = generateCouponCode();

  useEffect(() => {
    setnewCoupon(randomCoupon + "05");

    dataShare.getListofCoupons(searchFilter).then((data) => {
      if (data.rescode == 0 || data.rescode == 3) {
        setallCoupons([]);
      } else {
        setallCoupons(data);
      }
    });
  }, [searchFilter]);

  const removeCoupon = (couponId) => {
    dataShare.removeCoupon(couponId).then((data) => {
      if (data.rescode == 0 || data.rescode == 3) {
        setallCoupons([]);
      } else {
        setallCoupons(data.couponData);
        // console.log('removed', data)
        dataShare.showNewTost("Coupon removed", "info");
      }
    });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Coupons</li>
          </ol>
        </nav>
        <div className="mb-9">
          <div className="row g-3 flex-between-end mb-5">
            <div className="col-auto">
              <h2 className="mb-2">Manage Coupons</h2>
              <h5 className="text-700 fw-semi-bold">
                Coupons placed for all your coustomers.
              </h5>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-6 col-xl-6">
              <div className="position-sticky" style={{ top: 85 }}>
                <div className="row">
                  <div className="col-6">
                    <h4 className="mb-3">Coupon Code</h4>
                    <input
                      className="form-control mb-5 formField"
                      type="text"
                      placeholder="Cupon code here..."
                      id="couponCode"
                      value={newCoupon}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-6">
                    <h4 className="mb-3">Select Discount%</h4>
                    <select
                      className="form-select mb-3 formField"
                      id="couponDiscount"
                      value={selectPecent}
                      onChange={(e) => {
                        setselectPecent(e.target.value);
                        setnewCoupon(randomCoupon + e.target.value);
                      }}
                    >
                      <option value="05">05%</option>
                      <option value="10">10%</option>

                      <option value="15">15%</option>
                      <option value="20">20%</option>

                      <option value="25">25%</option>
                      <option value="30">30%</option>

                      <option value="35">35%</option>
                      <option value="40">40%</option>

                      <option value="45">45%</option>
                      <option value="50">50%</option>

                      <option value="65">65%</option>
                      <option value="60">60%</option>

                      <option value="75">75%</option>
                      <option value="80">80%</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="d-sm-flex justify-content-between">
                    <h2 className="mb-4">&nbsp;</h2>
                    <div className="d-flex mb-3">
                      <Link to="/dash">
                        <button className="btn btn-phoenix-primary me-2 px-6">
                          Cancel
                        </button>
                      </Link>
                      <button
                        tabIndex={2}
                        className="btn btn-primary px-6"
                        onClick={addNewCoupon}
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                </div>

                <h5 className="text-700 fw-semi-bold">
                  <strong className="badge badge-phoenix badge-phoenix-warning me-2">
                    Do not repeat same coupon code and manage coupon carefully.
                  </strong>
                  <br />
                  <br />
                  <Link to="/dash/customcoupons">
                    <h5 className="text-700 fw-semi-bold">
                      Create Custom Coupon
                    </h5>
                  </Link>
                </h5>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="row g-2">
                <div className="col-12 col-xl-12 mt-0">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Coupon Codes</h4>
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="col col-auto">
                            <div className="search-box">
                              <input
                                className="form-control search-input search"
                                type="search"
                                placeholder="Search brand"
                                aria-label="Search"
                                value={searchFilter}
                                onChange={(e) =>
                                  setsearchFilter(e.target.value)
                                }
                              />
                              <span className="fas fa-search search-box-icon" />
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2"></div>
                            {allCoupons.length > 0 ? (
                              <table className="table table-sm fs--1 mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      className="sort border-top ps-3"
                                      data-sort="name"
                                    >
                                      Brand Name
                                    </th>

                                    <th
                                      className="sort text-end align-middle pe-0 border-top"
                                      scope="col"
                                    >
                                      ACTION
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list">
                                  {allCoupons.map(function (b, idx) {
                                    return (
                                      <tr key={idx}>
                                        <td className="align-middle ps-3 name">
                                          {b.couponName}
                                        </td>

                                        <td className="align-middle white-space-nowrap text-end pe-0">
                                          <div className="font-sans-serif btn-reveal-trigger position-static">
                                            <button
                                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              data-boundary="window"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              data-bs-reference="parent"
                                            >
                                              <svg
                                                className="svg-inline--fa fa-ellipsis fs--2"
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="ellipsis"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                data-fa-i2svg=""
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"
                                                />
                                              </svg>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end py-2">
                                              <button
                                                onClick={() => {
                                                  removeCoupon(b._id);
                                                }}
                                                className="dropdown-item text-danger"
                                                href="advance-tables.html#!"
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        // $('.content').addClass("zzzzz")
        dataShare.onComponentLoad();
        if (
          userData.accessAllowed.admin ||
          userData.accessAllowed.productManager
        ) {
        } else {
          dataShare.showNewTost(
            "Invalid access for Product Management.",
            "error"
          );
          navigate("/dash");
        }
      }, [])}
    </>
  );
};

export const Brands = () => {};
