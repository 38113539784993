import "./App.css";
import { MainRoot } from "./components/MainRoot";
import ServiceState from "./components/services/ServiceState";

function App() {
  return (
    <>
      <ServiceState>
        <MainRoot />
      </ServiceState>
    </>
  );
}

export default App;
