import React from "react";
import { DashFooter } from "./CommonSections/DashFooter";

export const DashHelp = () => {
  return (
    <>
      <div className="content">
        <h2 className="mb-2 lh-sm">Help / Contact us...</h2>
        <div
          className="card shadow-none border border-300 my-4"
          data-component-card="data-component-card"
        >
          <div className="card-header p-4 border-bottom border-300 bg-soft">
            <div className="row g-3 justify-content-between align-items-center">
              <div className="col-12 col-md">
                <h4
                  className="text-900 mb-0"
                  data-anchor="data-anchor"
                  id="figma-file"
                >
                  Contact Administrator
                  <a
                    className="anchorjs-link "
                    aria-label="Anchor"
                    data-anchorjs-icon="#"
                    href="#figma-file"
                    style={{ paddingLeft: "0.375em" }}
                  />
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="p-4 code-to-copy">
              <h5 data-anchor="data-anchor" id="to-play-with-the-design">
                Contact Details
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  data-anchorjs-icon="#"
                  href="#to-play-with-the-design"
                  style={{ paddingLeft: "0.375em" }}
                />
              </h5>
              <ul>
                <li>
                    <code>Name :</code> Chaitanya Mestry 
                </li>
                <li>
                    <code>Phone :</code> 8879457679
                </li>
                <li>
                <code>Email :</code> <a href="mailto:chaitanya.mestry97@gmail.com">
                  chaitanya.mestry97@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="card shadow-none border border-300 my-4"
          data-component-card="data-component-card"
        >
          <div className="card-header p-4 border-bottom border-300 bg-soft">
            <div className="row g-3 justify-content-between align-items-center">
              <div className="col-12 col-md">
                <h4
                  className="text-900 mb-0"
                  data-anchor="data-anchor"
                  id="figma-file"
                >
                  Contact Developer
                  <a
                    className="anchorjs-link "
                    aria-label="Anchor"
                    data-anchorjs-icon="#"
                    href="#figma-file"
                    style={{ paddingLeft: "0.375em" }}
                  />
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="p-4 code-to-copy">
              <h5 data-anchor="data-anchor" id="to-play-with-the-design">
                Contact Details
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  data-anchorjs-icon="#"
                  href="#to-play-with-the-design"
                  style={{ paddingLeft: "0.375em" }}
                />
              </h5>
              <ul>
                <li>
                    <code>Name :</code> Shekhar Chavan
                </li>
                <li>
                    <code>Phone :</code> 8286227129
                </li>
                <li>
                <code>Email :</code> <a href="mailto:me@shekharchavan.com">
                    me@shekharchavan.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 5 }}
        >
          <div
            className="toast align-items-center text-white bg-dark border-0 light"
            id="icon-copied-toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body p-3" />
              <button
                className="btn-close btn-close-white me-2 m-auto"
                type="button"
                data-bs-dismiss="toast"
                aria-label="Close"
              />
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    </>
  );
};
