import { useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Dashroot } from "../components/dash/Dashroot";
import { LogIn } from "../components/auth/LogIn";
import { SingUp } from "../components/auth/SingUp";
import { InpHome } from "./public/InpHome";

import { DashHome } from "../components/dash/InnerComps/DashHome";
import { EditProfile } from "./dash/InnerComps/EditProfile";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServiceContext from "./services/ServiceContext";
import { TopLoadingBar } from "./TopLoadingBar";
import { ConfirmEmail } from "./auth/ConfirmEmail";
import { ResetPassAskEmail } from "./auth/ResetPassAskEmail";
import { ResetPass } from "./auth/ResetPass";
import { Error404 } from "./public/Error404";
import { ListEmps } from "./dash/InnerComps/Employees/ListEmps";
import { EditEmp } from "./dash/InnerComps/Employees/EditEmp";
import { AddNewEmp } from "./dash/InnerComps/Employees/AddNewEmp";
import { AddProduct } from "./dash/InnerComps/Products/AddProduct";
import { ListProducts } from "./dash/InnerComps/Products/ListProducts";
import { EditProducts } from "./dash/InnerComps/Products/EditProducts";
import { Brands } from "./dash/InnerComps/Brands/Brands";
import { DashHelp } from "./dash/InnerComps/DashHelp";
import { UsersSection } from "./dash/InnerComps/CRM/UsersSection";
import { CustomerDefails } from "./dash/InnerComps/CRM/CustomerDefails";
import { OrderList } from "./dash/InnerComps/CRM/OrderList";
import { OrderDetails } from "./dash/InnerComps/CRM/OrderDetails";
import { Coupon } from "./dash/InnerComps/CRM/Coupon";
import { Banners } from "./dash/InnerComps/Products/Banners";
import { CustomCoupon } from "./dash/InnerComps/CRM/CustomCoupon";

export const MainRoot = () => {
  const { dataShare, user, topBar, settopBar } = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const location = useLocation();
  useEffect(() => {
    dataShare.validateToken().then(() => {
      setIsLoading(false); // Set loading to false when promise resolves
      // console.log('test1')
    });
  }, [user, location, dataShare]);

  useEffect(() => {
    settopBar(true)
    setTimeout(() => {
      settopBar(false)
    }, 1500);
  }, [location]);

 

  if (isLoading) {
    // Render a loading indicator or any other content while waiting for the promise
    return <div>Loading...</div>;
  }

  return (
    <>
      {topBar ? <TopLoadingBar /> : <></>}

      <Routes>
        <Route exect path="/" element={<LogIn />} />
        <Route exect path="/login" element={<LogIn />} />
        <Route exect path="/signup" element={<SingUp />} />
        <Route exect path="/resetpassauth" element={<ResetPassAskEmail />} />
        <Route exect path="/resetpass/:authToken" element={<ResetPass />} />
        <Route  
          exect
          path="/verifyemail/:authToken"
          element={<ConfirmEmail />}
        />
        <Route
          exect
          path="/dash"
          element={user ? <Dashroot /> : <Navigate to="/login" replace />}
        >
          <Route exect path="" element={<DashHome />} />
          <Route exect path="profile" element={<EditProfile />} />
          
          <Route exect path="addemployee" element={<AddNewEmp />} />
          <Route exect path="employees" element={<ListEmps />} />
          <Route exect path="employees/:empid" element={<EditEmp />} />

          
          <Route exect path="addproduct" element={<AddProduct />} />
          <Route exect path="products" element={<ListProducts />} />
          <Route exect path="products/:prdid" element={<EditProducts />} />

          <Route exect path="users" element={<UsersSection />} />
          <Route exect path="users/:userid" element={<CustomerDefails />} /> 
          <Route exect path="orders" element={<OrderList />} />
          <Route exect path="orders/:orderid" element={<OrderDetails />} />

          <Route exect path="coupons" element={<Coupon />} />
          <Route exect path="customcoupons" element={<CustomCoupon />} />

          <Route exect path="banners" element={<Banners />} />
          

          <Route exect path="brands" element={<Brands />} />

          <Route exect path="help" element={<DashHelp />} />
          
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ToastContainer />
    </>
  );
};
