import React, { useContext, useEffect, useState } from "react";
// import $ from 'jquery';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { DashFooter } from "../CommonSections/DashFooter";

export const Brands = () => {
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allBrands, setallBrands] = useState([]);
  const [newBrand, setnewBrand] = useState("");
  const [searchFilter, setsearchFilter] = useState("");

  const addNewBrand = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });
    let formIsValid = true;
    let invalidFields = [];

    if (newBrand.length < 2) {
      formIsValid = false;
      invalidFields.push("brdName");
    }

    if (formIsValid) {
      const FD = new FormData();
      let brdData = {
        brdName: newBrand,
        brdAddedBy: userData.id,
      };
      FD.append("brdData", JSON.stringify(brdData));
      dataShare.addNewBrand(FD).then((data) => {
        if (data.rescode == 0 || data.rescode == 3) {
            dataShare.showNewTost("Brand with same name already exist", "error");
        }else{
          setallBrands(data.brdData);
          dataShare.showNewTost("Brand Added", "success");
          setnewBrand("");
        }
        
      });
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  useEffect(() => {
    dataShare.getListofBrands(searchFilter).then((data) => {
      if (data.rescode == 0 || data.rescode == 3) {
        setallBrands([]);
        
      } else {
        setallBrands(data);
      }
    });
  }, [searchFilter]);

  const removeBrand = (brdId) => {
    dataShare.removeBrand(brdId).then((data) => {
      if (data.rescode == 0 || data.rescode == 3) {
        setallBrands([]);
      } else {
        setallBrands(data.brdData);
        // console.log('removed', data)
        dataShare.showNewTost("Brand removed", "info");
      }
    });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Brands</li>
          </ol>
        </nav>
        <div className="mb-9">
          <div className="row g-3 flex-between-end mb-5">
            <div className="col-auto">
              <h2 className="mb-2">Manage Brands</h2>
              <h5 className="text-700 fw-semi-bold">
                Brands placed across your store
              </h5>
            </div>
          </div>
          
          <div className="row g-5">
            <div className="col-12 col-xl-6">
            <div className="position-sticky" style={{ top: 85 }}>
            <h4 className="mb-3">Brand Name*</h4>
              <input
                className="form-control mb-5 formField"
                type="text"
                placeholder="Brand name here..."
                id="brdName"
                value={newBrand}
                tabIndex={1}
                onChange={(e) => {
                  setnewBrand(e.target.value);
                  e.target.classList.remove("is-invalid");
                }}
              />

              <div className="col-xl-12">
                <div className="d-sm-flex justify-content-between">
                  <h2 className="mb-4">&nbsp;</h2>
                  <div className="d-flex mb-3">
                    <Link to="/dash">
                      <button className="btn btn-phoenix-primary me-2 px-6">
                        Cancel
                      </button>
                    </Link>
                    <button
                      tabIndex={2}
                      className="btn btn-primary px-6"
                      onClick={addNewBrand}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>

              <h5 className="text-700 fw-semi-bold">
                <strong className="badge badge-phoenix badge-phoenix-warning me-2">
                  Do not repeat same brand with different names and manage
                  brands carefully.
                </strong>
                <br />
                <br />
                <strong>
                  This will allow only to add a brand specific option while
                  adding or updating product
                </strong>
                <br />
                Also this will help to user filter brand specific products.
              </h5>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="row g-2">
                <div className="col-12 col-xl-12 mt-0">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Brands</h4>
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="col col-auto">
                            <div className="search-box">
                              <input
                                className="form-control search-input search"
                                type="search"
                                placeholder="Search brand"
                                aria-label="Search"
                                value={searchFilter}
                                onChange={(e) =>
                                  setsearchFilter(e.target.value)
                                }
                              />
                              <span className="fas fa-search search-box-icon" />
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2"></div>
                            {allBrands.length > 0 ? (<table className="table table-sm fs--1 mb-0">
                              <thead>
                                <tr>
                                  <th
                                    className="sort border-top ps-3"
                                    data-sort="name"
                                  >
                                    Brand Name
                                  </th>

                                  <th
                                    className="sort text-end align-middle pe-0 border-top"
                                    scope="col"
                                  >
                                    ACTION
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="list">
                                {allBrands.map(function (b, idx) {
                                  return (
                                    <tr key={idx}>
                                      <td className="align-middle ps-3 name">
                                        {b.brdName}
                                      </td>

                                      <td className="align-middle white-space-nowrap text-end pe-0">
                                        <div className="font-sans-serif btn-reveal-trigger position-static">
                                          <button
                                            className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            data-boundary="window"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            data-bs-reference="parent"
                                          >
                                            <svg
                                              className="svg-inline--fa fa-ellipsis fs--2"
                                              aria-hidden="true"
                                              focusable="false"
                                              data-prefix="fas"
                                              data-icon="ellipsis"
                                              role="img"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 448 512"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="currentColor"
                                                d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"
                                              />
                                            </svg>
                                          </button>
                                          <div className="dropdown-menu dropdown-menu-end py-2">
                                            <button
                                              onClick={() => {
                                                removeBrand(b._id);
                                              }}
                                              className="dropdown-item text-danger"
                                              href="advance-tables.html#!"
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>): (<></>)}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        // $('.content').addClass("zzzzz")
        dataShare.onComponentLoad();
        if (
          userData.accessAllowed.admin ||
          userData.accessAllowed.productManager
        ) {
        } else {
          dataShare.showNewTost(
            "Invalid access for Product Management.",
            "error"
          );
          navigate("/dash");
        }
      }, [])}
    </>
  );
};
