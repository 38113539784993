import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import ServiceContext from "../services/ServiceContext";
import { useParams, useNavigate } from "react-router-dom";

export const ResetPass = () => {
  const { dataShare } = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  let { authToken } = useParams();
  const navigate = useNavigate();

  const defaultValues = {
    password: "",
    confirmPassword: "",
  };
  const [formFieldData, setformFieldData] = useState(defaultValues);

  const customPassReset = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    let formIsValid = true;
    let invalidFields = [];

    if (
      formFieldData.password !== "" &&
      formFieldData.confirmPassword !== "" &&
      formFieldData.password === formFieldData.confirmPassword &&
      formFieldData.password.length > 3
    ) {
    } else {
      formIsValid = false;
      invalidFields.push("reset_password");
      invalidFields.push("reset_confirmPassword");
    }

    if (formIsValid) {
      resertPassNow();
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  const resertPassNow = () => {

    dataShare
      .resertPassNow(authToken, formFieldData.password)
      .then((data) => {
        if (data) {
          settempUserData(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [tempUserData, settempUserData] = useState({});
  useEffect(() => {
    dataShare
      .getUserDataUsingToken(authToken)
      .then((data) => {
        setIsLoading(false); // Set loading to false when promise resolves
        if (data) {
          settempUserData(data);
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        //   toast(err.message);
      });
  }, []);
  if (isLoading) {
    // Render a loading indicator or any other content while waiting for the promise
    return <div>Loading...</div>;
  }
  return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
                <div className="text-center mb-5">
                  <div className="avatar avatar-4xl mb-4">
                    <img
                      className="rounded-circle"
                      src={tempUserData.photoURL || process.env.REACT_APP_USER_PROFILE}
                      alt=""
                      referrerPolicy="no-referrer"
                    />
                  </div>

                  <h2 className="text-1000">
                    {" "}
                    <span className="fw-normal">Hello </span>
                    {tempUserData.fname} {tempUserData.lname}
                  </h2>
                  <br />
                  <div className="text-center mb-6">
                    <h4 className="text-1000">Reset new password</h4>
                    <p className="text-700">Type your new password</p>
                    <div className="mt-5">
                      <input
                        className="form-control mb-2 formField"
                        id="reset_password"
                        type="password"
                        placeholder="Type new password"
                        value={formFieldData.password}
                        onChange={(e) => {
                          setformFieldData({
                            ...formFieldData,
                            password: e.target.value,
                          });
                          e.target.classList.remove("is-invalid");
                        }}
                      />
                      <input
                        className="form-control mb-4 formField"
                        id="reset_confirmPassword"
                        type="password"
                        placeholder="Cofirm new password"
                        value={formFieldData.confirmPassword}
                        onChange={(e) => {
                          setformFieldData({
                            ...formFieldData,
                            confirmPassword: e.target.value,
                          });
                          e.target.classList.remove("is-invalid");
                        }}
                      />
                      <button
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={customPassReset}
                      >
                        Set Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
