import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { DashFooter } from "../CommonSections/DashFooter";
import Choices from "choices.js";
export const AddProduct = () => {
  const { dataShare, userData } = useContext(ServiceContext);

  const [chooseColor, setchooseColor] = useState(null);
  const [chooseSize, setchooseSize] = useState(null);
  const [chooseAge, setchooseAge] = useState(null);
  const [chooseCat, setchooseCat] = useState(null);
  const navigate = useNavigate();

  const defaultProductData = {
    prdName: "",
    prdDesc: "",
    prdMRP: 0,
    prdSP: 0,
    prdGender: "",
    prdColor: [],
    prdSize: [],
    prdForAge: [],
    prdCategory: [],
    prdBrand: "",
    prdTags: [],
    prdQty: 0,
    prdRating: "",
    prdFeatureImg: "",
    prdPublished: false,
  };

  // Image Upload
  const [selectedFile, setSelectedFile] = useState([]);
  const [tempImage, settempImage] = useState([]);
  const [featuredImg, setfeaturedImg] = useState(0);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };
  };

  const updateImagesUI = () => {
    // console.log(selectedFile);
    settempImage([]);
    if (selectedFile.length) {
      for (let i = 0; i < selectedFile.length; i++) {
        getBase64(selectedFile[i], (result) => {
          settempImage((tempImage) => [...tempImage, result]);
        });
      }
      setproductData({
        ...productData,
        prdFeatureImg: 0,
      });
      setfeaturedImg(0);
    } else {
      setproductData({
        ...productData,
        prdFeatureImg: 0,
      });
      setfeaturedImg(0);
    }
  };

  const removePrdImg = (index) => {
    setSelectedFile((cp) => selectedFile.filter((cp, i) => i !== index));
  };

  const removeTag = (index) => {
    let newItems = [...productData.prdTags];
    newItems.splice(index, 1);
    setproductData({
      ...productData,
      prdTags: newItems,
    });
  };

  useEffect(() => {
    updateImagesUI();
  }, [selectedFile]);

  const resetImageUpload = () => {
    // setSelectedFile([]);
  };

  const handleFileChange = async (e) => {
    // // For Multiple
    let currentFiles = e.target.files;
    let cCount = selectedFile.length;
    console.log(cCount);

    for (let i = 0; i < currentFiles.length; i++) {
      if (cCount < 4) {
        let currentFile = e.target.files[i];
        if (e.target.files.length >= 0) {
          if (
            currentFile.type === "image/jpeg" ||
            currentFile.type === "image/png"
          ) {
            const objectURL = URL.createObjectURL(currentFile);
            const FR = new FileReader();
            FR.readAsDataURL(currentFile);
            FR.onload = (e) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = () => {
                if (currentFile.size < 4 * 1024 * 1024) {
                  currentFile.id =
                    i +
                    Date.now() *
                      Math.floor(Math.random() * (999 - 100 + 1) + 100);
                  setSelectedFile((selectedFile) => [
                    ...selectedFile,
                    currentFile,
                  ]);
                } else {
                  resetImageUpload();
                  console.log(
                    "Image should be square and image size should be less than 2MB"
                  );
                  dataShare.showNewTost(
                    "Image should be square and image size should be less than 2MB",
                    "warning"
                  );
                }
              };
            };
          } else {
            resetImageUpload();
            console.log("Invalid Image Format");
            dataShare.showNewTost("Invalid Image Format", "warning");
          }
        } else {
          resetImageUpload();
        }
        cCount++;
      } else {
        dataShare.showNewTost(
          "You cannot upload more that 4 images",
          "warning"
        );
      }
    }
    document.getElementById("prdImgs").classList.remove("is-invalid");
  };

  const [productData, setproductData] = useState(defaultProductData);

  const handleResetProd = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });
    setproductData(defaultProductData);
    chooseColor.removeActiveItems();
    chooseSize.removeActiveItems();
    chooseAge.removeActiveItems();
    chooseCat.removeActiveItems();
    setSelectedFile([]);
  };

  const addProductNow = async (publicBool) => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    let formIsValid = true;
    let invalidFields = [];
    if (productData.prdName.length < 4) {
      formIsValid = false;
      invalidFields.push("prdName");
    }
    if (productData.prdDesc.length < 4) {
      formIsValid = false;
      invalidFields.push("prdDesc");
    }
    if (productData.prdMRP == 0) {
      formIsValid = false;
      invalidFields.push("prdMRP");
    }
    if (productData.prdSP == 0) {
      formIsValid = false;
      invalidFields.push("prdSP");
    }
    if (productData.prdQty == 0) {
      formIsValid = false;
      invalidFields.push("prdQty");
    }
    if (productData.prdGender == "") {
      formIsValid = false;
      invalidFields.push("prdGender");
    }
    if (selectedFile.length == 0) {
      formIsValid = false;
      invalidFields.push("prdImgs");
    }

    if (formIsValid) {
      //   console.log("Ready to Submit...");

      const FD = new FormData();
      FD.append("id", userData.id);
      FD.append("fileType", "product");
      if (selectedFile) {
        for (const file of selectedFile) {
          FD.append("files", file);
        }
      }
      productData.prdPublished = publicBool;
      FD.append("prdData", JSON.stringify(productData));

      dataShare.addProductNow(FD).then((data) => {
        console.log(data);
        if (data.rescode == 1) {
          dataShare.showNewTost("Product Added.", "success");
          handleResetProd();
        } else if (data.rescode == 3) {
          dataShare.showNewTost(
            "Product with same name already exist",
            "warning"
          );
          invalidFields.push("prdName");
          invalidFields.forEach((e) => {
            document.getElementById(e).classList.add("is-invalid");
          });
        } else {
          dataShare.showNewTost("Somting went wrong.", "error");
        }
      });
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  const [allBrands, setallBrands] = useState([]);
  useEffect(() => {
    dataShare.getListofBrands("").then((data) => {
      if (data.rescode == 0 || data.rescode == 3) {
        setallBrands([]);
      } else {
        setallBrands(data);
      }
    });
  }, []);

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dash/products">Products</Link>
            </li>
            <li className="breadcrumb-item active">Add Product</li>
          </ol>
        </nav>
        <div className="mb-9">
          <div className="row g-3 flex-between-end mb-5">
            <div className="col-auto">
              <h2 className="mb-2">Add a Product</h2>
              <h5 className="text-700 fw-semi-bold">
                Products placed across your store
              </h5>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-phoenix-secondary me-2 mb-2 mb-sm-0"
                type="button"
                onClick={handleResetProd}
              >
                Discard
              </button>
              <button
                className="btn btn-phoenix-primary me-2 mb-2 mb-sm-0"
                type="button"
                onClick={() => addProductNow(false)}
              >
                Save draft
              </button>
              <button
                className="btn btn-primary mb-2 mb-sm-0"
                type="button"
                onClick={() => addProductNow(true)}
              >
                Publish product
              </button>
            </div>
          </div>
          <h4 className="mb-3">Product Title*</h4>
          <div className="row g-5">
            <div className="col-12 col-xl-8">
              <input
                className="form-control mb-5 formField"
                type="text"
                placeholder="Write title here..."
                id="prdName"
                value={productData.prdName}
                onChange={(e) => {
                  setproductData({
                    ...productData,
                    prdName: e.target.value,
                  });
                  e.target.classList.remove("is-invalid");
                }}
              />
              <div className="mb-6">
                <h4 className="mb-3"> Product Description*</h4>
                <textarea
                  className="form-control mb-5 formField"
                  name="content"
                  style={{ height: "150px" }}
                  value={productData.prdDesc}
                  id="prdDesc"
                  onChange={(e) => {
                    setproductData({
                      ...productData,
                      prdDesc: e.target.value,
                    });
                    e.target.classList.remove("is-invalid");
                  }}
                />
              </div>

              <h4 className="mb-3">Display images*</h4>
              <div
                className="dropzone dropzone-multiple p-0 mb-5 dz-clickable dz-file-processing dz-file-complete"
                id="my-awesome-dropzone"
                // data-dropzone="data-dropzone"
              >
                <input
                  type="file"
                  className="d-none"
                  id="uploadPrdImgs"
                  multiple
                  onChange={handleFileChange}
                />
                <label htmlFor="uploadPrdImgs" className="col-sm-12">
                  <div className="dz-preview d-flex flex-wrap" />
                  <div
                    id="prdImgs"
                    className="dz-message text-600 text-center formField"
                    data-dz-message="data-dz-message"
                  >
                    Drag your photo here <span className="text-800">or</span>&nbsp;
                    <button className="btn btn-link p-0" type="button"
                    style={{pointerEvents: "none"}}>
                      Browse from device
                    </button>
                    <br />
                    <img
                      className="mt-3 me-2"
                      src="../../../assets/img/icons/image-icon.png"
                      width={40}
                      alt=""
                    />
                  </div>
                </label>
              </div>
              <div className="row g-3 mb-3">
                {selectedFile.length ? (
                  tempImage.map(function (imgPath, idx) {
                    return (
                      <div className="col-3 position-relative" key={idx}>
                        <span
                          className="custom_remove_p_i_b "
                          onClick={() => removePrdImg(idx)}
                          id={idx}
                        >
                          <span className="close" />
                        </span>
                        <div
                          className="ratio ratio-1x1 border rounded-2"
                          onClick={() => {
                            // console.log(selectedFile[idx].id)
                            setfeaturedImg(idx);
                            setproductData({
                              ...productData,
                              prdFeatureImg: idx,
                            });
                          }}
                        >
                          <img
                            className="w-100 rounded-3 img_asc "
                            src={imgPath}
                            alt=""
                          />
                        </div>
                        {featuredImg == idx ? (
                          <span className="badge bg-success fs--2 product-verified-badge featuredImgofPrd">
                            Primary
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>

              <h4 className="mb-3">Inventory</h4>
              <div className="row g-0 border-top border-bottom border-300">
                <div className="col-sm-4">
                  <div
                    className="nav flex-sm-column border-bottom border-bottom-sm-0 border-end-sm border-300 fs--1 vertical-tab h-100 justify-content-between"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <span className="nav-link border-end border-end-sm-0 border-300 text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-top active">
                      <div className="col-sm-12">
                        <span
                          className="me-sm-2 fs-4 nav-icons"
                          data-feather="tag"
                        />
                        <span className="d-none d-sm-inline">Pricing</span>
                        <br />
                        <br />
                        <span
                          className="me-sm-2 fs-4 nav-icons"
                          data-feather="package"
                        />
                        <span className="d-none d-sm-inline">Restock</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="tab-content py-3 ps-sm-4 h-100">
                    <div
                      className="tab-pane fade show active"
                      id="pricingTabContent"
                      role="tabpanel"
                    >
                      <h4 className="mb-3 d-sm-none">Pricing</h4>
                      <div className="row g-3">
                        <div className="col-12 col-lg-6">
                          <h5 className="mb-2 text-1000">Regular price*</h5>
                          <input
                            className="form-control formField"
                            type="text"
                            placeholder="Per Product in ₹"
                            id="prdMRP"
                            value={productData.prdMRP}
                            onChange={(e) => {
                              setproductData({
                                ...productData,
                                prdMRP: e.target.value.replace(/[^0-9]/g, ""),
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                        </div>
                        <div className="col-12 col-lg-6">
                          <h5 className="mb-2 text-1000">Sale price*</h5>
                          <input
                            className="form-control formField"
                            type="text"
                            placeholder="Per Product in ₹"
                            id="prdSP"
                            value={productData.prdSP}
                            onChange={(e) => {
                              setproductData({
                                ...productData,
                                prdSP: e.target.value.replace(/[^0-9]/g, ""),
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                        </div>
                        <br />
                        <h5 className="mb-3 text-1000 mt-5">Add to Stock*</h5>
                        <div
                          className="row g-3 flex-1 mb-4"
                          style={{ marginTop: 0 }}
                        >
                          <div className="col-sm-6" style={{ marginTop: 0 }}>
                            <input
                              className="form-control formField"
                              type="number"
                              placeholder="Quantity"
                              id="prdQty"
                              value={productData.prdQty}
                              onChange={(e) => {
                                setproductData({
                                  ...productData,
                                  prdQty: e.target.value.replace(/[^0-9]/g, ""),
                                });
                                e.target.classList.remove("is-invalid");
                              }}
                            />
                          </div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: 200 }} />
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-1000 fw-bold py-1">
                                Per Product MRP:
                              </td>
                              <td className="text-700 fw-semi-bold py-1">
                                ₹{productData.prdMRP || 0}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-1000 fw-bold py-1">
                                Per Product SP:
                              </td>
                              <td className="text-700 fw-semi-bold py-1">
                                ₹{productData.prdSP || 0}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-1000 fw-bold py-1">
                                Product count
                              </td>
                              <td className="text-700 fw-semi-bold py-1">
                                {productData.prdQty || 0}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-1000 fw-bold py-1">
                                Total Stock Amount
                              </td>
                              <td className="text-700 fw-semi-bold py-1">
                                SP ₹
                                {productData.prdSP * productData.prdQty || 0}{" "}
                                (MRP ₹
                                {productData.prdMRP * productData.prdQty || 0})
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade h-100"
                      id="restockTabContent"
                      role="tabpanel"
                      aria-labelledby="restockTab"
                    >
                      <div className="d-flex flex-column h-100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="row g-2">
                <div className="col-12 col-xl-12">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Organize</h4>
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2">
                              <h5 className="mb-0 text-1000 me-2">Gender*</h5>
                            </div>
                            <select
                              className="form-select mb-3 formField"
                              id="prdGender"
                              value={productData.prdGender}
                              onChange={(e) => {
                                setproductData({
                                  ...productData,
                                  prdGender: e.target.value,
                                });
                                e.target.classList.remove("is-invalid");
                              }}
                            >
                              <option value="">Select Gender</option>
                              <option value="men">Men Clothing</option>
                              <option value="women">Womens Clothing</option>
                              <option value="kid">Kid Clothing</option>
                              <option value="all">For All</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="mb-4">
                            <div className="d-flex flex-wrap mb-2">
                              <h5 className="mb-0 text-1000 me-2">Brand</h5>
                              <Link
                                className="fw-bold fs--1"
                                to="/dash/brands"
                                style={{ position: "relative", top: "-2px" }}
                              >
                                Add new brand
                              </Link>
                            </div>
                            <select
                              className="form-select mb-3"
                              aria-label="category"
                              id="prdBrand"
                              value={productData.prdBrand}
                              onChange={(e) => {
                                setproductData({
                                  ...productData,
                                  prdBrand: e.target.value,
                                });
                                e.target.classList.remove("is-invalid");
                              }}
                            >
                              <option value="">Select brand</option>
                              {allBrands.map(function (b, idx) {
                                return (
                                  <option key={idx} value={b.brdName}>
                                    {" "}
                                    {b.brdName}
                                  </option>
                                );
                              })}
                              {/* <option value="Nike">Nike</option>
                              <option value="H&M">H&M</option>
                              <option value="Adidas">Adidas</option>
                              <option value="GAP">GAP</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="mb-xl-4">
                            <h5 className="mb-2 text-1000">Keywords / Tags</h5>
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Collection"
                                id="prdTags"
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    if (e.target.value !== "") {
                                      setproductData({
                                        ...productData,
                                        prdTags: [
                                          ...productData.prdTags,
                                          e.target.value,
                                        ],
                                      });
                                      e.target.value = "";
                                      e.target.classList.remove("is-invalid");
                                    } else {
                                      e.target.classList.add("is-invalid");
                                    }
                                  }
                                }}
                              />
                              {/* <span className="input-group-text c_pointer">
                                +
                              </span> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-12 mt-0">
                          {productData.prdTags.map((txt, indx) => {
                            return (
                              <span
                                className="badge bg-success fs--2 product-verified-badge tags_p"
                                key={indx}
                              >
                                {txt}
                                <button
                                  className="tags_cb"
                                  onClick={() => {
                                    removeTag(indx);
                                  }}
                                >
                                  <i className="fa fa-close tags_ci" />
                                </button>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="border-bottom border-dashed border-sm-0">
                            <h5 className="card-title mb-1">Colors</h5>
                            <select
                              className="form-select"
                              id="prdColor"
                              // data-choices="data-choices"
                              multiple="multiple"
                              data-options='{"removeItemButton":true,"placeholder":true}'
                              onChange={(e) => {
                                let newData = chooseColor.getValue(true);
                                setproductData({
                                  ...productData,
                                  prdColor: newData,
                                });
                              }}
                            >
                              <option value="Red">Red</option>
                              <option value="Orange">Orange</option>
                              <option value="Yellow">Yellow</option>
                              <option value="Green">Green</option>
                              <option value="Blue">Blue</option>
                              <option value="Purple">Purple</option>
                              <option value="Pink">Pink</option>
                              <option value="Brown">Brown</option>
                              <option value="Gray">Gray</option>
                              <option value="Black">Black</option>
                              <option value="White">White</option>
                              <option value="Cyan">Cyan</option>
                              <option value="Magenta">Magenta</option>
                              <option value="Turquoise">Turquoise</option>
                              <option value="Indigo">Indigo</option>
                              <option value="Violet">Violet</option>
                              <option value="Maroon">Maroon</option>
                              <option value="Teal">Teal</option>
                              <option value="Lavender">Lavender</option>
                              <option value="Gold">Gold</option>
                              <option value="Silver">Silver</option>
                              <option value="Beige">Beige</option>
                              <option value="Peach">Peach</option>
                              <option value="Olive">Olive</option>
                              <option value="Navy">Navy</option>
                              <option value="Cream">Cream</option>
                              <option value="Coral">Coral</option>
                              <option value="Sky Blue">Sky Blue</option>
                              <option value="Charcoal">Charcoal</option>
                              <option value="Mint Green">Mint Green</option>
                              <option value="Ruby">Ruby</option>
                              <option value="Lemon">Lemon</option>
                              <option value="Bronze">Bronze</option>
                              <option value="Lilac">Lilac</option>
                              <option value="Rose">Rose</option>
                              <option value="Periwinkle">Periwinkle</option>
                              <option value="Plum">Plum</option>
                              <option value="Mauve">Mauve</option>
                              <option value="Slate">Slate</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="d-flex flex-wrap mb-2">
                            <h5 className="text-1000 me-2">Size</h5>
                          </div>
                          <select
                            className="form-select"
                            id="prdSize"
                            // data-choices="data-choices"
                            multiple="multiple"
                            data-options='{"removeItemButton":true,"placeholder":true}'
                            onChange={(e) => {
                              let newData = chooseSize.getValue(true);
                              setproductData({
                                ...productData,
                                prdSize: newData,
                              });
                            }}
                          >
                            <option value="XS">XS (Extra Small)</option>
                            <option value="S">S (Small)</option>
                            <option value="M">M (Medium)</option>
                            <option value="L">L (Large)</option>
                            <option value="XL">XL (Extra Large)</option>
                            <option value="XXL">
                              XXL (Double Extra Large)
                            </option>
                            <option value="XXXL">
                              XXXL (Triple Extra Large)
                            </option>
                          </select>
                        </div>

                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="d-flex flex-wrap mb-2">
                            <h5 className="text-1000 me-2">Category</h5>
                            {/* <a
                              className="fw-bold fs--1"
                              href="add-product.html#!"
                            >
                              Add new category
                            </a> */}
                          </div>
                          <select
                            className="form-select"
                            id="prdCategory"
                            // data-choices="data-choices"
                            multiple="multiple"
                            data-options='{"removeItemButton":true,"placeholder":true}'
                            onChange={(e) => {
                              let newData = chooseCat.getValue(true);
                              setproductData({
                                ...productData,
                                prdCategory: newData,
                              });
                            }}
                          >
                            <optgroup label="Tops">
                              <option value="T-shirts">T-shirts</option>
                              <option value="Blouses">Blouses</option>
                              <option value="Shirts">Shirts</option>
                              <option value="Sweaters">Sweaters</option>
                              <option value="Hoodies">Hoodies</option>
                              <option value="Tank tops">Tank tops</option>
                              <option value="Polo shirts">Polo shirts</option>
                            </optgroup>
                            <optgroup label="Bottoms">
                              <option value="Pants">Pants</option>
                              <option value="Jeans">Jeans</option>
                              <option value="Shorts">Shorts</option>
                              <option value="Skirts">Skirts</option>
                              <option value="Leggings">Leggings</option>
                              <option value="Trousers">Trousers</option>
                            </optgroup>
                            <optgroup label="Dresses">
                              <option value="Casual dresses">
                                Casual dresses
                              </option>
                              <option value="Formal dresses">
                                Formal dresses
                              </option>
                              <option value="Evening gowns">
                                Evening gowns
                              </option>
                              <option value="Maxi dresses">Maxi dresses</option>
                              <option value="Sundresses">Sundresses</option>
                            </optgroup>
                          </select>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-12">
                          <div className="d-flex flex-wrap mb-2">
                            <h5 className="text-1000 me-2">Age Groups</h5>
                          </div>
                          <select
                            className="form-select"
                            id="prdForAge"
                            // data-choices="data-choices"
                            multiple="multiple"
                            data-options='{"removeItemButton":true,"placeholder":true}'
                            onChange={(e) => {
                              let newData = chooseAge.getValue(true);
                              setproductData({
                                ...productData,
                                prdForAge: newData,
                              });
                            }}
                          >
                            <option value="1-2Y">Age 1-2 Years</option>
                            <option value="2-3Y">Age 2-3 Years</option>
                            <option value="3-4Y">Age 3-4 Years</option>
                            <option value="4-5Y">Age 4-5 Years</option>
                            <option value="5-6Y">Age 5-6 Years</option>
                            <option value="6-7Y">Age 6-7 Years</option>
                            <option value="7-8Y">Age 7-8 Years</option>
                            <option value="8-9Y">Age 8-9 Years</option>
                            <option value="9-10Y">Age 9-10 Years</option>
                            <option value="10-11Y">Age 10-11 Years</option>
                            <option value="11-12Y">Age 11-12 Years</option>
                            <option value="12-13Y">Age 12-13 Years</option>
                            <option value="13-14Y">Age 13-14 Years</option>
                            <option value="14-15Y">Age 14-15 Years</option>
                            <option value="15-16Y">Age 15-16 Years</option>
                            <option value="16-17Y">Age 16-17 Years</option>
                            <option value="17-18Y">Age 17-18 Years</option>
                            <option value="18+">Age 18+ Years</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        dataShare.onComponentLoad();
        if (
          userData.accessAllowed.admin ||
          userData.accessAllowed.productManager
        ) {
        } else {
          dataShare.showNewTost(
            "Invalid access for Product Management.",
            "error"
          );
          navigate("/dash");
        }

        if (chooseColor == null) {
          setchooseColor(
            new Choices(document.getElementById("prdColor"), {
              removeItemButton: true,
              allowHTML: true,
            })
          );
        }
        if (chooseSize == null) {
          setchooseSize(
            new Choices(document.getElementById("prdSize"), {
              removeItemButton: true,
              allowHTML: true,
            })
          );
        }
        if (chooseAge == null) {
          setchooseAge(
            new Choices(document.getElementById("prdForAge"), {
              removeItemButton: true,
              allowHTML: true,
            })
          );
        }
        if (chooseCat == null) {
          setchooseCat(
            new Choices(document.getElementById("prdCategory"), {
              removeItemButton: true,
              allowHTML: true,
            })
          );
        }
      }, [])}
    </>
  );
};
