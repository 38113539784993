import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import { Invoice } from "./Invoice";
import { useReactToPrint } from "react-to-print";
import generatePDF from "react-to-pdf";
import { PaymentStatusCondition } from "./InternalBlocks/PaymentStatusCondition";
import { OrderStatusCondition } from "./InternalBlocks/OrderStatusCondition";

export const OrderDetails = () => {
  const { dataShare, userData } = useContext(ServiceContext);
  const navigate = useNavigate();
  let { orderid } = useParams();
  const [cartData, setcartData] = useState();

  const [allCustomData, setallCustomData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");

  useEffect(() => {
    dataShare.getOrderDetails(orderid).then((data) => {
      if (data.rescode == 0) {
        setallCustomData([]);
      } else {
        setallCustomData(data.customData);
        setcartData(data.customData.cartData);
        console.log(allCustomData);
      }
    });
  }, []);

  const updateOrderStatus = () => {
    dataShare.updateOrderStatus(orderid, allCustomData).then((data) => {
      if (data.rescode == 0) {
        setallCustomData([]);
      } else {
        setallCustomData(data.customData);
        setcartData(data.customData.cartData);
      }
    });
  };

  const [showInvoice, setshowInvoice] = useState(false);
  const componentRef = useRef(true);

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handlePrint = () => {
    generatePDF(componentRef, { filename: `${orderid}_epicattirehub.pdf` });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dash/orders">Orders</Link>
            </li>
            {allCustomData ? (
              <li className="breadcrumb-item active" aria-current="page">
                Order ID: {allCustomData.OrderId}
              </li>
            ) : (
              <li className="breadcrumb-item">
                <Link to="/dash/orders">No Order Found</Link>
              </li>
            )}
          </ol>
        </nav>
        {allCustomData.OrderId ? (
          <>
            {showInvoice ? (
              <>
                <div className="d-flex justify-content-between align-items-end mb-4">
                  <h2 className="mb-0">Invoice</h2>
                  <div>
                    <button
                      className="btn btn-phoenix-secondary me-2"
                      onClick={() => {
                        setshowInvoice(!showInvoice);
                      }}
                    >
                      <span className="fa-solid fa-arrow-left me-sm-2" />
                      <span className="d-none d-sm-inline-block">Go Back</span>
                    </button>
                    {/* <button className="btn btn-phoenix-secondary me-2">
                  <span className="fa-solid fa-download me-sm-2" />
                  <span className="d-none d-sm-inline-block">
                    Download Invoice
                  </span>
                </button> */}
                    <button
                      className="btn btn-phoenix-secondary"
                      onClick={handlePrint}
                    >
                      <span className="fa-solid fa-download me-sm-2" />
                      <span className="d-none d-sm-inline-block">Download</span>
                    </button>
                  </div>
                </div>
                <div>
                  <Invoice
                    allProductsData={allCustomData}
                    dataShare={dataShare}
                    setshowInvoice={setshowInvoice}
                    showInvoice={showInvoice}
                    forDownload={true}
                  />
                </div>

                <div
                  ref={componentRef}
                  style={{
                    width: "1400px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    position: "absolute",
                    left: "-999999999px",
                    top: "-999999999px",
                  }}
                >
                  <Invoice
                    allProductsData={allCustomData}
                    dataShare={dataShare}
                    setshowInvoice={setshowInvoice}
                    showInvoice={showInvoice}
                    forDownload={true}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    {/* <button className="btn btn-phoenix-secondary me-2">
                  <span className="fa-solid fa-download me-sm-2" />
                  <span className="d-none d-sm-inline-block">
                    Download Invoice
                  </span>
                </button> */}
                    {/* <button
                      className="btn btn-phoenix-secondary"
                      onClick={handlePrint}
                    >
                      <span className="fa-solid fa-print me-sm-2" />
                      <span className="d-none d-sm-inline-block">Print</span>
                    </button> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-9">
                <h2 className="mb-0">
                  Order <span>{allCustomData.OrderId}</span>
                </h2>
                <div className="d-flex flex-wrap flex-between-center mb-1">
                  <p className="text-800 lh-sm mb-0">
                    Customer ID :{" "}
                    <Link
                      className="fw-bold"
                      to={"/dash/users/" + allCustomData.OrderById}
                    >
                      {allCustomData.OrderById}
                    </Link>
                  </p>

                  <div className="d-flex">
                    <PaymentStatusCondition
                      status={allCustomData.paymentStatus}
                    />
                    &nbsp; &nbsp;
                    <OrderStatusCondition status={allCustomData.orderStatus} />
                  </div>
                </div>

                <div className="row g-5 gy-7">
                  <div className="col-12 col-xl-8 col-xxl-9">
                    {cartData ? (
                      <>
                        <div className="table-responsive scrollbar mx-n1 px-1">
                          {/* {console.log(cartData.cartPrds)} */}
                          {cartData.cartPrds ? (
                            <>
                              <div className="col-12 col-lg-12">
                                <div id="cartTable" className="mt-4">
                                  {cartData ? (
                                    <>
                                      <h3 className="card-title mb-1">
                                        Cart Data
                                      </h3>
                                      <table className="table fs--1 mb-0 border-top border-200 mb-4">
                                        <thead>
                                          <tr>
                                            <th
                                              className="sort white-space-nowrap align-middle fs--2"
                                              scope="col"
                                            />
                                            <th
                                              className="sort white-space-nowrap align-middle"
                                              scope="col"
                                              style={{ minWidth: 150 }}
                                            >
                                              PRODUCTS
                                            </th>
                                            <th
                                              className="sort align-middle"
                                              scope="col"
                                              style={{ width: 80 }}
                                            >
                                              COLOR
                                            </th>
                                            <th
                                              className="sort align-middle"
                                              scope="col"
                                              style={{ width: 300 }}
                                            >
                                              AGE GRP
                                            </th>
                                            <th
                                              className="sort align-middle"
                                              scope="col"
                                              style={{ width: 150 }}
                                            >
                                              SIZE
                                            </th>
                                            <th
                                              className="sort align-middle text-end"
                                              scope="col"
                                              style={{ width: 300 }}
                                            >
                                              PRICE
                                            </th>
                                            <th
                                              className="sort align-middle text-end"
                                              scope="col"
                                              style={{ width: 300 }}
                                            >
                                              QTY
                                            </th>

                                            <th
                                              className="sort align-middle text-end"
                                              scope="col"
                                              style={{ width: 250 }}
                                            >
                                              TOTAL
                                            </th>
                                            <th
                                              className="sort text-end align-middle pe-0"
                                              scope="col"
                                            />
                                          </tr>
                                        </thead>
                                        <tbody
                                          className="list"
                                          id="cart-table-body"
                                        >
                                          {cartData.cartPrds.map((prd, i) => {
                                            const imgset = prd.prdImages.find(
                                              (element) => element.feature
                                            );
                                            let UTM =
                                              dataShare.encodeToUrlFriendly(
                                                prd.prdName
                                              );
                                            return (
                                              <tr
                                                className="cart-table-row btn-reveal-trigger"
                                                key={i}
                                              >
                                                <td className="align-middle white-space-nowrap py-0">
                                                  <div className="border rounded-2">
                                                    <img
                                                      src={imgset.w100}
                                                      alt=""
                                                      width={53}
                                                    />
                                                  </div>
                                                </td>
                                                <td className="products align-middle">
                                                  <Link
                                                    target="_blank"
                                                    className="fw-semi-bold mb-0 line-clamp-2"
                                                    to={
                                                      process.env
                                                        .REACT_APP_STATIC_BASE_UI +
                                                      "filter/" +
                                                      UTM
                                                    }
                                                  >
                                                    {prd.prdName}
                                                  </Link>
                                                </td>
                                                <td className="color align-middle white-space-nowrap fs--1 text-900">
                                                  {prd.prdColor}
                                                </td>
                                                <td className="products align-middle">
                                                  {prd.prdForAge}
                                                </td>

                                                <td className="size align-middle white-space-nowrap text-700 fs--1 fw-semi-bold">
                                                  {prd.prdSize}
                                                </td>
                                                <td className="price align-middle text-900 fs--1 fw-semi-bold text-end">
                                                  ₹{" "}
                                                  {dataShare.formatAmount(
                                                    prd.prdSP
                                                  )}
                                                </td>
                                                <td className="price align-middle text-900 fs--1 fw-semi-bold text-end">
                                                  {prd.qty}
                                                </td>
                                                <td className="price align-middle text-900 fs--1 fw-semi-bold text-end">
                                                  ₹{" "}
                                                  {dataShare.formatAmount(
                                                    prd.prdSP * prd.qty
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                      <div className="table-responsive scrollbar mx-n1 px-1">
                                        {/* {console.log(allCustomData)} */}
                                        <div className="d-flex align-items-end">
                                          <h3 className="mb-0 me-3">
                                            Shipping Details
                                          </h3>
                                        </div>
                                        <table className="table table-borderless mt-4">
                                          <tbody>
                                            <tr>
                                              <td className="py-2 ps-0">
                                                <div className="d-flex">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16px"
                                                    height="16px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-user fs-5 me-2"
                                                    style={{
                                                      height: 16,
                                                      width: 16,
                                                    }}
                                                  >
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                                    <circle
                                                      cx={12}
                                                      cy={7}
                                                      r={4}
                                                    />
                                                  </svg>
                                                  <h5 className="lh-sm me-4">
                                                    Name
                                                  </h5>
                                                </div>
                                              </td>
                                              <td className="py-2 fw-bold lh-sm">
                                                :
                                              </td>
                                              <td className="py-2 px-3">
                                                <h5 className="lh-sm fw-normal text-800">
                                                  {
                                                    allCustomData.ShippingUserName
                                                  }
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="py-2 ps-0">
                                                <div className="d-flex">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16px"
                                                    height="16px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-home fs-5 me-2"
                                                    style={{
                                                      height: 16,
                                                      width: 16,
                                                    }}
                                                  >
                                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                                    <polyline points="9 22 9 12 15 12 15 22" />
                                                  </svg>
                                                  <h5 className="lh-sm me-4">
                                                    Address
                                                  </h5>
                                                </div>
                                              </td>
                                              <td className="py-2 fw-bold lh-sm">
                                                :
                                              </td>
                                              <td className="py-2 px-3">
                                                <h5 className="lh-lg fw-normal text-800">
                                                  {
                                                    allCustomData.ShippingAddr
                                                      .addrLine1
                                                  }
                                                  ,{" "}
                                                  {
                                                    allCustomData.ShippingAddr
                                                      .addrLine2
                                                  }
                                                  <br />
                                                  {
                                                    allCustomData.ShippingAddr
                                                      .city
                                                  }
                                                  ,{" "}
                                                  {
                                                    allCustomData.ShippingAddr
                                                      .state
                                                  }
                                                  ,{" "}
                                                  {
                                                    allCustomData.ShippingAddr
                                                      .zip
                                                  }
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="py-2 ps-0">
                                                <div className="d-flex">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16px"
                                                    height="16px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-phone fs-5 me-2"
                                                    style={{
                                                      height: 16,
                                                      width: 16,
                                                    }}
                                                  >
                                                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                                                  </svg>
                                                  <h5 className="lh-sm me-4">
                                                    Phone
                                                  </h5>
                                                </div>
                                              </td>
                                              <td className="py-2 fw-bold lh-sm">
                                                :{" "}
                                              </td>
                                              <td className="py-2 px-3">
                                                <h5 className="lh-sm fw-normal text-800">
                                                  {allCustomData.ShippingPhone}
                                                </h5>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr className="my-6" />
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <p>Cart is empty.</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="col-12 col-xl-4 col-xxl-3">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="col-12">
                            {/* <button
                            className="btn btn-phoenix-secondary me-2 mb-2 float-right"
                            onClick={() => {
                              setshowInvoice(!showInvoice);
                            }}
                          >
                            <span className="d-none d-sm-inline-block">
                              View Invoice
                            </span>
                          </button> */}
                          </div>
                          <span
                            className="badge badge-phoenix fs--8 badge-phoenix-info c_pointer"
                            onClick={() => {
                              setshowInvoice(!showInvoice);
                            }}
                          >
                            <span className="badge-label">View Invoice</span>
                          </span>
                          <br />
                          &nbsp;
                          <h3 className="card-title mb-4">Order Status</h3>{" "}
                          <h6 className="mb-2">
                            Payment status {allCustomData.paymentStatus}
                          </h6>
                          <select
                            className="form-select mb-4"
                            aria-label="delivery type"
                            // disabled={true}
                            defaultValue={allCustomData.paymentStatus}
                            onChange={(e) => {
                              // if(e.target.value == "paid"){
                              //   console.log(1)
                              //   setallCustomData({
                              //     ...allCustomData,
                              //     orderStatus: "confirmed",
                              //   });
                              // }else{
                              //   console.log(0)
                              //   setallCustomData({
                              //     ...allCustomData,
                              //     orderStatus: "canceled",
                              //   });
                              // }

                              setallCustomData({
                                ...allCustomData,
                                paymentStatus: e.target.value,
                              });
                            }}
                          >
                            <option value="canceled">Canceled</option>
                            <option value="pendding">Pendding</option>
                            <option value="paid">Paid</option>
                            {/* <option value="refund">Refunded</option> */}
                            {/* <option value="rejected">Rejected</option> */}
                            {/* <option value="cod">Cash on delivery</option> */}
                          </select>
                          <h6 className="mb-2">
                            Order status {allCustomData.orderStatus}
                          </h6>
                          <select
                            className="form-select mb-4"
                            aria-label="delivery type"
                            // defaultValue={allCustomData.orderStatus}
                            value={allCustomData.orderStatus}
                            onChange={(e) => {
                              setallCustomData({
                                ...allCustomData,
                                orderStatus: e.target.value,
                              });
                            }}
                          >
                            <option value="canceled">Canceled</option>
                            <option value="rejected">Rejected</option>
                            <option value="pendding">Pendding</option>
                            <option value="confirmed">Ready To Pickup</option>
                            <option value="processed">
                              Package Sent For Delivary
                            </option>
                            <option value="delivered">Order Delivered</option>
                            <option value="refund">Refunded</option>
                          </select>
                          <h6 className="mb-2">
                            Note on Order (Visible to end user)
                          </h6>
                          <textarea
                            className="form-control mb-3"
                            rows={4}
                            value={allCustomData.orderComments}
                            onChange={(e) => {
                              setallCustomData({
                                ...allCustomData,
                                orderComments: e.target.value,
                              });
                            }}
                          />
                          <button
                            className="btn btn-primary w-100"
                            onClick={updateOrderStatus}
                          >
                            Update Now
                          </button>
                          <hr />
                          {allCustomData.orderModifiedBy ? (
                            <>
                              <h6 className="mb-2">
                                Last Updated By ID <br></br>
                                <span className="lh-sm fw-normal text-800">
                                  {allCustomData.orderModifiedBy}
                                  <br />
                                </span>
                              </h6>
                              <h6 className="mb-2">
                                Last Updated By Name <br></br>
                                <span className="lh-sm fw-normal text-800">
                                  {allCustomData.orderModifiedByName}
                                  <br />
                                </span>
                              </h6>

                              <h6 className="mb-2">
                                Last Updated By Email <br></br>
                                <span className="lh-sm fw-normal text-800">
                                  {allCustomData.orderModifiedByEmail}
                                  <br />
                                </span>
                              </h6>

                              <h6 className="mb-2">
                                Last Updated On <br></br>
                                <span className="lh-sm fw-normal text-800">
                                  {dataShare.printDateBasedOnTP(
                                    allCustomData.orderModifiedOn
                                  ) +
                                    ", " +
                                    dataShare.printTimeBasedOnTP(
                                      allCustomData.orderModifiedOn
                                    )}
                                  <br />
                                </span>
                              </h6>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      {cartData ? (
                        <div className="card mb-3 mt-4">
                          <div className="card-body">
                            <div className="d-flex flex-between-center mb-3">
                              <h3 className="card-title mb-0">Summary</h3>
                            </div>
                            <select
                              className="form-select mb-3"
                              aria-label="delivery type"
                            >
                              <option value="online">online</option>
                            </select>
                            <div>
                              <div className="d-flex justify-content-between">
                                <p className="text-900 fw-semi-bold">
                                  Items subtotal :
                                </p>
                                <p className="text-1100 fw-semi-bold">
                                  ₹{dataShare.formatAmount(cartData.finalPrice)}
                                </p>
                              </div>

                              <div className="d-flex justify-content-between">
                                <p className="text-900 fw-semi-bold">
                                  Tax({cartData.taxPercent}) :
                                </p>
                                <p className="text-1100 fw-semi-bold">
                                  ₹{dataShare.formatAmount(cartData.taxAmt)}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="text-900 fw-semi-bold">
                                  Subtotal :
                                </p>
                                <p className="text-1100 fw-semi-bold">
                                  ₹
                                  {dataShare.formatAmount(
                                    cartData.amountWithTax
                                  )}
                                </p>
                              </div>

                              {cartData.couponCode ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <p className="text-900 fw-semi-bold">
                                      Coupon Code - {cartData.couponCode}
                                      {/* <span
                                      className="cursor_p"
                                      // onClick={removeCoupon}
                                    >
                                      <span className="fas fa-trash cursor_p" />
                                    </span> */}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p className="text-900 fw-semi-bold">
                                      <span className="text-danger">
                                        Discount ({cartData.getDiscountPercent}
                                        %):
                                      </span>
                                    </p>
                                    <p className="text-danger fw-semi-bold">
                                      -₹
                                      {dataShare.formatAmount(
                                        cartData.discountAmount || 0
                                      )}
                                    </p>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p className="text-900 fw-semi-bold">
                                      Subtotal with discount :
                                    </p>
                                    <p className="text-1100 fw-semi-bold">
                                      ₹
                                      {dataShare.formatAmount(
                                        cartData.amountWithDiscount || 0
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="d-flex justify-content-between">
                                <p className="text-900 fw-semi-bold">
                                  Shipping Cost :
                                </p>
                                <p className="text-1100 fw-semi-bold">
                                  ₹
                                  {dataShare.formatAmount(
                                    cartData.shippingCost
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between border-y border-dashed py-3 mb-4">
                              <h4 className="mb-0">Total :</h4>
                              <h4 className="mb-">
                                ₹{dataShare.formatAmount(cartData.grandTotal)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex flex-wrap flex-between-center mb-1 col-6">
                    <p className="text-800 lh-sm mb-0">
                      Order ID : {allCustomData.OrderId}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap flex-between-center mb-1 col-6">
                    <p className="text-800 lh-sm mb-0">
                      Receipt ID :{" "}
                      {allCustomData?.callbackData?.order?.entity?.receipt}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap flex-between-center mb-1 col-6">
                    <p className="text-800 lh-sm mb-0">
                      Payment ID :{" "}
                      {allCustomData?.callbackData?.payment?.entity?.id}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap flex-between-center mb-1 col-6">
                    <p className="text-800 lh-sm mb-0">
                      Bank Transactio Id :{" "}
                      {
                        allCustomData?.callbackData?.payment?.entity
                          ?.acquirer_data?.bank_transaction_id
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>No order Found</>
        )}

        <DashFooter />
      </div>
    </>
  );
};

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
