import React, { useContext, useEffect, useState } from "react";
import ServiceContext from "../../../services/ServiceContext";
import { DashFooter } from "../CommonSections/DashFooter";

export const ImageUploadSection = (props) => {
  let imageID = props.imgId;
  const { dataShare, userData } = useContext(ServiceContext);
  let currentFD = {};

  const updateParent = (img) =>{
    img.imageID = imageID

    let newObject1 = {
        imageID: imageID,
        imageFile: img
    }
    props.setNewImgData(newObject1)
  } 


  const [uploadB, setuploadB] = useState(false);

  if (imageID == "kv1-d") {
    currentFD.photoURL = props.kvData.Banner1.desktop;
  }
  if (imageID == "kv1-m") {
    currentFD.photoURL = props.kvData.Banner1.mobile;
  }

  if (imageID == "kv2-d") {
    currentFD.photoURL = props.kvData.Banner2.desktop;
  }
  if (imageID == "kv2-m") {
    currentFD.photoURL = props.kvData.Banner2.mobile;
  }

  if (imageID == "kv3-d") {
    currentFD.photoURL = props.kvData.Banner3.desktop;
  }
  if (imageID == "kv3-m") {
    currentFD.photoURL = props.kvData.Banner3.mobile;
  }

  if (imageID == "kv5-d") {
    currentFD.photoURL = props.kvData.Banner5.desktop;
  }
  if (imageID == "kv5-m") {
    currentFD.photoURL = props.kvData.Banner5.mobile;
  }

  // console.log(currentFD.photoURL);

  const [formData, setformData] = useState(currentFD);

  // Image Upload
  const [selectedFile, setSelectedFile] = useState(null);
  const resetImageUpload = () => {
    setSelectedFile(null);
    setformData({
      ...formData,
      photoURL: currentFD.photoURL,
    });
    setuploadB(false);
  };

  const [saveBtn, setsaveBtn] = useState(true);
  const handleFileChange = async (e) => {
    let currentFile = e.target.files[0];
    // // For Multiple
    // let currentFile = e.target.files;
    // setSelectedFile(currentFile)
    if (e.target.files.length === 1) {
      if (
        currentFile.type === "image/jpeg" ||
        currentFile.type === "image/png"
      ) {
        const objectURL = URL.createObjectURL(currentFile);
        const FR = new FileReader();
        FR.readAsDataURL(currentFile);
        FR.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            if (currentFile.size < 4 * 1024 * 1024) {
              setSelectedFile(currentFile);
              setformData({
                ...formData,
                photoURL: objectURL,
              });
              setuploadB(true);
              updateParent(currentFile)
            } else {
              resetImageUpload();
              // console.log(
              //   "Image should be square and image size should be less than 2MB"
              // );
              dataShare.showNewTost("Image should be less than 2MB", "warning");
              setuploadB(false);
            }
          };
        };
      } else {
        resetImageUpload();
        console.log("Invalid Image Format");
        dataShare.showNewTost("Invalid Image Format", "warning");
      }
    } else {
      resetImageUpload();
    }
  };

  return (
    <>
      <input
        className="d-none"
        id={imageID}
        type="file"
        onChange={handleFileChange}
      />
      <label className="col-12" htmlFor={imageID}>
        <img
          className="bg-white shadow-sm banner_img"
          src={formData.photoURL}
          referrerPolicy="no-referrer"
          width="100%"
          alt=""
        />
      </label>
      {/* <br />
      <br />
      {uploadB? (<button className="btn btn-primary btn-sm me-1 mb-1" type="button">
        Upload Now
      </button>): (<></>)} */}
      
    </>
  );
};
