import React from "react";
import {useContext} from "react";
import { Nav } from "./Nav";
import { Outlet } from "react-router-dom";
import ServiceContext from "../services/ServiceContext";
import { EmailNotConfirmed } from "./InnerComps/EmailNotConfirmed";
import { AccessDeniedPage } from "./InnerComps/AccessDeniedPage";

export const Dashroot = () => {
  // const navigate = useNavigate();
  const { userData } = useContext(ServiceContext);
  // console.log(userData)
   return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <Nav />
          {
            userData.emailverified ? 
              (<>
                {userData.accessType == "employee" ? (
                <Outlet />):
                (<AccessDeniedPage/>)}
              </>): 
              (<EmailNotConfirmed />)
            }
        </div>
      </main>
    </>
  );
};
