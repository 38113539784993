import React, { useContext, useEffect } from "react";
import ServiceContext from "../services/ServiceContext";
import { Sidenav } from "./Sidenav";
import { Topnav } from "./Topnav";

export const Nav = () => {
  const {dataShare} = useContext(ServiceContext);
  
  return (
    <>


      <Sidenav/>
      <Topnav/>
      

      {useEffect(() => {
        dataShare.onComponentLoad();
      }, [])}
    </>
  );
};
