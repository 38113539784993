import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import { AccessDeniedEmp } from "./AccessDeniedEmp";
import { Pagination } from "../CRM/Pagination";

export const ListEmps = () => {
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allUsersData, setallUsersData] = useState([]);

  const callCountLimit = 10;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: "",
    limit: callCountLimit,
    skip: 0,
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    dataShare.getListofEmps(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        // dataShare.showNewTost("Invalid Access", "error");
        setallUsersData([]);
      } else {
        setallUsersData(data.allDBUsers);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter]);

  return userData.accessAllowed.admin ||
    userData.accessAllowed.employeeManager ? (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Employees</li>
          </ol>
        </nav>
        <h2 className="text-bold text-1100 mb-5">Employees</h2>
        <div>
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <input
                  className="form-control search-input search"
                  type="search"
                  placeholder="Search employee"
                  aria-label="Search"
                  value={callDataFilter.filter}
                  onChange={(e) => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filter: e.target.value,
                      skip: 0,
                    });
                    setcallCountData({
                      ...callCountData,
                      activePage: 0,
                    });
                  }}
                />
                <span className="fas fa-search search-box-icon" />
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <Link to="/dash/addemployee">
                  <button className="btn btn-primary">
                    <span className="fas fa-plus me-2" />
                    Add employee
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs--1 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="customer"
                      // style={{ width: "15%", minWidth: 200 }}
                    >
                      ID
                    </th>
                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="customer"
                      // style={{ width: "15%", minWidth: 200 }}
                    >
                      Name
                    </th>
                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="email"
                      // style={{ width: "15%", minWidth: 200 }}
                    >
                      EMAIL
                    </th>
                    <th
                      className="sort align-middle pe-3"
                      scope="col"
                      data-sort="mobile_number"
                      // style={{ width: "20%", minWidth: 200 }}
                    >
                      MOBILE NUMBER
                    </th>

                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="last_active"
                      // style={{ width: "21%", minWidth: 200 }}
                    >
                      Access Allowed
                    </th>
                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="city"
                      // style={{ width: "10%" }}
                    >
                      accessType
                    </th>
                    <th
                      className="sort align-middle"
                      scope="col"
                      data-sort="city"
                      // style={{ width: "10%" }}
                    >
                      Email Verified
                    </th>
                    <th
                      className="sort align-middle text-end pe-0"
                      scope="col"
                      data-sort="joined"
                      // style={{ width: "19%", minWidth: 200 }}
                    >
                      JOINED ON
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {allUsersData.map(function (u, idx) {
                    if (u._id == userData.id || u.accessAllowed.admin) {
                      //Do nothing
                    } else {
                      return (
                        <tr
                          key={idx}
                          className="hover-actions-trigger btn-reveal-trigger position-static"
                        >
                          <td className="email align-middle white-space-nowrap">
                            {idx + 1}
                          </td>
                          <td className="customer align-middle white-space-nowrap">
                            <Link
                              className="d-flex align-items-center text-900 text-hover-1000"
                              to={"/dash/employees/" + u._id}
                            >
                              <div className="avatar avatar-m">
                                <img
                                  className="rounded-circle"
                                  src={
                                    u.photoURL ||
                                    process.env.REACT_APP_USER_PROFILE
                                  }
                                  alt=""
                                />
                              </div>
                              <h6 className="mb-0 ms-3 fw-semi-bold">
                                {u.fname} {u.lname}
                              </h6>
                            </Link>
                          </td>
                          <td className="email align-middle white-space-nowrap">
                            <Link
                              className="fw-semi-bold"
                              to={"/dash/employees/" + u._id}
                            >
                              {u.email}
                            </Link>
                          </td>
                          <td className="mobile_number align-middle white-space-nowrap">
                            {u.phone || "NA"}
                          </td>

                          <td className="last_active align-middle white-space-nowrap text-700">
                            {u.accessAllowed.admin ? (
                              <span className="badge badge-phoenix badge-phoenix-success me-2">
                                Admin
                              </span>
                            ) : (
                              <></>
                            )}

                            {u.accessAllowed.crmManager ? (
                              <span className="badge badge-phoenix badge-phoenix-primary me-2">
                                CRM
                              </span>
                            ) : (
                              <></>
                            )}

                            {u.accessAllowed.employeeManager ? (
                              <span className="badge badge-phoenix badge-phoenix-secondary me-2">
                                Employee
                              </span>
                            ) : (
                              <></>
                            )}

                            {u.accessAllowed.productManager ? (
                              <span className="badge badge-phoenix badge-phoenix-warning me-2">
                                Product
                              </span>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="city align-middle white-space-nowrap text-900">
                            {u.accessType == "employee" ? (
                              <span className="badge badge-phoenix badge-phoenix-success me-2">
                                Employee
                              </span>
                            ) : u.accessType == "blocked" ? (
                              <span className="badge badge-phoenix badge-phoenix-secondary me-2">
                                Blocked
                              </span>
                            ) : (
                              <span className="badge badge-phoenix badge-phoenix-warning me-2">
                                Pendding
                              </span>
                            )}
                          </td>
                          <td className="city align-middle white-space-nowrap text-900">
                            {u.emailverified ? (
                              <span className="badge badge-phoenix badge-phoenix-success me-2">
                                Verified
                              </span>
                            ) : (
                              <span className="badge badge-phoenix badge-phoenix-warning me-2">
                                Not Verified
                              </span>
                            )}
                          </td>
                          <td className="joined align-middle white-space-nowrap text-700 text-end">
                            {dataShare.printDateBasedOnTP(u.timestamp) || "NA"}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
            <Pagination 
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
              />
          </div>
        </div>
        <DashFooter />
      </div>
    </>
  ) : (
    <AccessDeniedEmp />
  );
};
