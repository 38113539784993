import React, { useContext, useEffect, useState } from "react";
import ServiceContext from "../../services/ServiceContext";
import { DashFooter } from "./CommonSections/DashFooter";
// import { useLocation } from "react-router-dom";

export const EditProfile = () => {
  const { dataShare, userData } = useContext(ServiceContext);

  let currentFD = {
    fname: userData.fname,
    lname: userData.lname,
    email: userData.email,
    photoURL: userData.photoURL || process.env.REACT_APP_USER_PROFILE,
    phone: userData.phone || "",
    description: userData.description || "",

    facebook: userData.facebook || "",
    linkedin: userData.linkedin || "",
    github: userData.github || "",
    twitter: userData.twitter || "",
    instagram: userData.instagram || "",
    website: userData.website || "",
  };

  

  const [formData, setformData] = useState(currentFD);

  const resetImageUpload = () => {
    setSelectedFile(null);
    setformData({
      ...formData,
      photoURL: userData.photoURL || process.env.REACT_APP_USER_PROFILE,
    });
  };

  // Image Upload
  const [selectedFile, setSelectedFile] = useState(null);

  const [saveBtn, setsaveBtn] = useState(true);
  const handleFileChange = async (e) => {
    let currentFile = e.target.files[0];
    // // For Multiple
    // let currentFile = e.target.files;
    // setSelectedFile(currentFile)
    if (e.target.files.length === 1) {
      if (
        currentFile.type === "image/jpeg" ||
        currentFile.type === "image/png"
      ) {
        const objectURL = URL.createObjectURL(currentFile);
        const FR = new FileReader();
        FR.readAsDataURL(currentFile);
        FR.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            if (currentFile.size < 4 * 1024 * 1024) {
              setSelectedFile(currentFile);
              setformData({
                ...formData,
                photoURL: objectURL,
              });
            } else {
              resetImageUpload();
              console.log(
                "Image should be square and image size should be less than 2MB"
              );
              dataShare.showNewTost(
                "Image should be square and image size should be less than 2MB",
                "warning"
              );
            }
          };
        };
      } else {
        resetImageUpload();
        console.log("Invalid Image Format");
        dataShare.showNewTost("Invalid Image Format", "warning");
      }
    } else {
      resetImageUpload();
    }
  };

  const resetForm = () => {
    setformData(currentFD);
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });
    setSelectedFile(null);
    // document.getElementById("upload-porfile-picture").value = ""
  };

  useEffect(() => {
    setTimeout(() => {
      resetForm();
    }, 1000);
  }, [userData]);
  const updateProfile = () => {
    const phoneReg = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    
    const facebookReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const linkedinReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const githubReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const twitterReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const instagramReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const websiteReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    
    setsaveBtn(false);
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    let formIsValid = true;
    let invalidFields = [];

    if (formData.fname.length < 2) {
      formIsValid = false;
      invalidFields.push("p_fname");
    }
    if (formData.lname.length < 2) {
      formIsValid = false;
      invalidFields.push("p_lname");
    }

    if (formData.phone.length > 0) {
      if (phoneReg.test(formData.phone)) {
        // console.log('Valid')
      } else {
        formIsValid = false;
        // console.log('Invalid')
        invalidFields.push("p_phone");
      }
    }


    // Validate Social

    if (formData.facebook.length > 0) {
      if (facebookReg.test(formData.facebook)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_facebook");
      }
    }
    if (formData.linkedin.length > 0) {
      if (linkedinReg.test(formData.linkedin)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_linkedin");
      }
    }
    if (formData.github.length > 0) {
      if (githubReg.test(formData.github)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_github");
      }
    }
    if (formData.twitter.length > 0) {
      if (twitterReg.test(formData.twitter)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_twitter");
      }
    }
    if (formData.instagram.length > 0) {
      if (instagramReg.test(formData.instagram)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_instagram");
      }
    }
    if (formData.website.length > 0) {
      if (websiteReg.test(formData.website)) {
      } else {
        formIsValid = false;
        invalidFields.push("p_website");
      }
    }

    if (formIsValid) {
      const FD = new FormData();
      FD.append("id", userData.id);
      FD.append("fileType", "profile");
      if (selectedFile) {
        FD.append("files", selectedFile);
        // for (const file of selectedFile) {
        //   FD.append('files', file);
        // }
      }

      let sendJsonObj = {
        fname: formData.fname,
        lname: formData.lname,
        phone: formData.phone,
        description: formData.description,

        facebook: formData.facebook,
        linkedin: formData.linkedin,
        github: formData.github,
        twitter: formData.twitter,
        instagram: formData.instagram,
        website: formData.website,
      };
      FD.append("data", JSON.stringify(sendJsonObj));

      dataShare.updateProfileNow(FD).then(() => {
        dataShare.validateToken().then(() => {
          setsaveBtn(true);
          dataShare.showNewTost("Data Updated", "success");
        });
      });
      setsaveBtn(true);
    } else {
      dataShare.showNewTost("Please corss check your data.", "warning");
      setsaveBtn(true);
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  return (
    <>
      <div className="content">
        <div className="mb-9">
          <div className="row g-6">
            <div className="col-12 col-xl-4">
              <div className="card cover-image mb-5">
                <div
                  className="card-header hover-actions-trigger position-relative mb-6"
                  style={{ minHeight: 130 }}
                >
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage:
                        "linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%), url(../../assets/img/generic/59.png)",
                    }}
                  ></div>
                  <input
                    className="d-none"
                    id="upload-porfile-picture"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label
                    className="avatar avatar-4xl status-online feed-avatar-profile cursor-pointer"
                    htmlFor="upload-porfile-picture"
                  >
                    <img
                      className="rounded-circle img-thumbnail bg-white shadow-sm"
                      src={formData.photoURL}
                      referrerPolicy="no-referrer"
                      width={200}
                      alt=""
                    />
                  </label>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex flex-wrap mb-2 align-items-center">
                        <h3 className="me-2">
                          {userData.fname} {userData.lname}
                        </h3>
                        <span className="fw-normal fs-0"></span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          <span className="fa-solid fa-user-group fs--2 me-2 me-lg-1 me-xl-2" />
                          <h6 className="d-inline-block mb-0">
                            1297
                            <span className="fw-semi-bold ms-1 me-4">
                              Followers
                            </span>
                          </h6>
                        </div>
                        <div className="d-flex">
                          <span className="fa-solid fa-user-check fs--2 me-2 me-lg-1 me-xl-2" />
                          <h6 className="d-block d-xl-inline-block mb-0">
                            3971
                            <span className="fw-semi-bold ms-1">Following</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8">
              <div className="border-bottom border-300 mb-4">
                <div className="mb-6">
                  <h4 className="mb-4">Personal Information</h4>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input formField"
                            id="p_fname"
                            type="text"
                            placeholder="First Name*"
                            value={formData.fname}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                fname: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="firstName"
                          >
                            FIRST NAME*
                          </label>
                        </div>
                        <span className="fa-solid fa-user text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input formField"
                            id="p_lname"
                            type="text"
                            placeholder="Last Name*"
                            value={formData.lname}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                lname: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="lastName"
                          >
                            LAST NAME*
                          </label>
                        </div>
                        <span className="fa-solid fa-user text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input formField"
                            id="p_email"
                            type="email"
                            placeholder="Email*"
                            value={userData.email}
                            onChange={() => {}}
                            disabled={true}
                            readOnly={true}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="emailSocial"
                          >
                            EMAIL*
                          </label>
                        </div>
                        <span className="fa-solid fa-envelope text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input formField"
                            id="p_phone"
                            type="tel"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                phone: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="phone"
                          >
                            ENTER YOUR PHONE
                          </label>
                        </div>
                        <span className="fa-solid fa-phone text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <textarea
                            className="form-control form-icon-input formField"
                            id="p_description"
                            style={{ height: 115 }}
                            placeholder="Info"
                            value={formData.description}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                description: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="info"
                          >
                            Info
                          </label>
                        </div>
                        <span className="fa-solid fa-circle-info text-900 fs--1 form-icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-6">
                  <h4 className="mb-4">Social</h4>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_facebook"
                            type="text"
                            placeholder="Facebook"
                            value={formData.facebook}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                facebook: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_facebook"
                          >
                            Facebook
                          </label>
                        </div>
                        <span className="fa-brands fa-facebook text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_twitter"
                            type="text"
                            placeholder="Twitter"
                            value={formData.twitter}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                twitter: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_twitter"
                          >
                            Twitter
                          </label>
                        </div>
                        <span className="fa-brands fa-twitter text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_linkedin"
                            type="text"
                            placeholder="Linkedin"
                            value={formData.linkedin}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                linkedin: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_linkedin"
                          >
                            linkedin
                          </label>
                        </div>
                        <span className="fa-brands fa-linkedin-in text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_instagram"
                            type="text"
                            placeholder="instagram"
                            value={formData.instagram}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                instagram: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_instagram"
                          >
                            instagram
                          </label>
                        </div>
                        <span className="fa-brands fa-instagram text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_github"
                            type="text"
                            placeholder="github"
                            value={formData.github}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                github: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_github"
                          >
                            github
                          </label>
                        </div>
                        <span className="fa-brands fa-github text-900 fs--1 form-icon" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-icon-container">
                        <div className="form-floating">
                          <input
                            className="form-control form-icon-input"
                            id="p_website"
                            type="text"
                            placeholder="Website"
                            value={formData.website}
                            onChange={(e) => {
                              setformData({
                                ...formData,
                                website: e.target.value,
                              });
                              e.target.classList.remove("is-invalid");
                            }}
                          />
                          <label
                            className="text-700 form-icon-label"
                            htmlFor="p_website"
                          >
                            Website
                          </label>
                        </div>
                        <span className="fa-solid fa-globe text-900 fs--1 form-icon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-end mb-6">
                  <div>
                    <button
                      className="btn btn-phoenix-secondary me-2"
                      onClick={resetForm}
                      disabled={!saveBtn}
                    >
                      Cancel Changes
                    </button>

                    {saveBtn ? (
                      <button
                        className="btn btn-phoenix-primary"
                        onClick={updateProfile}
                      >
                        Save Information
                      </button>
                    ) : (
                      <button
                        className="btn btn-phoenix-primary"
                        disabled={true}
                      >
                        Saving Information
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row gy-5">
                <div className="col-12 col-md-6">
                  <h4 className="text-black">Transfer Ownership</h4>
                  <p className="text-700">
                    Transfer this account to another person or to a company
                    repository.
                  </p>
                  <button className="btn btn-phoenix-warning">Transfer</button>
                </div>
                <div className="col-12 col-md-6">
                  <h4 className="text-black">Account Deletion</h4>
                  <p className="text-700">
                    Transfer this account to another person or to a company
                    repository.
                  </p>
                  <button className="btn btn-phoenix-danger">
                    Delete account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>
      {useEffect(() => {
        dataShare.onComponentLoad();
      }, [])}
    </>
  );
};
