import React from "react";
// import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";

export const SevenDaysGraph = (props) => {
  // console.log(props.data)
  let dates = [];
  let orderCount1 = [];
  let orderCount2 = [];
  props.data.map((odata, id) => {
    dates.push(odata.date);
    orderCount1.push(odata.count);
  });
  props.data2.map((odata, id) => {
    orderCount2.push(odata.count);
  });

  // console.log(dates);
  // console.log(orderCount1);
  // console.log(orderCount2);

//   const dates = ['2024-02-01', '2024-02-02', '2024-02-03', '2024-02-04', '2024-02-05', '2024-02-06'];
// const orderCount1 = [120, 150, 200, 180, 220, 190]; // Example data for the first series
// const orderCount2 = [90, 100, 120, 110, 130, 140]; // Example data for the second series

//   const options = {
//     // ECharts options
//     tooltip: {
//       trigger: "axis",
//       axisPointer: {
//         type: "cross",
//       },
//     },
//     xAxis: {
//       data: dates,
//       axisLabel: {
//         width: 80, //fixed number of pixels
//         overflow: "truncate", // or 'break' to continue in a new line
//         interval: 0,
//         rotate: 40,
//       },
//     },
//     yAxis: {
//       type: "value",
//       splitLine: {
//         show: false
//       }
//     },
//     series: [
//       {
//         visible: false,
//         data: orderCount,
//         barWidth: "50%",
//         type: "line",
        
//       },
//     ],
//   };



const options = {
    // ECharts options
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      data: dates,
      axisLabel: {
        width: 80, //fixed number of pixels
        overflow: "truncate", // or 'break' to continue in a new line
        interval: 0,
        rotate: 40,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      splitLine: {
        show: false
      }
    },
    series: [
      {
        name: 'Orders',
        data: orderCount1, // First set of data
        barWidth: "50%",
        type: "line",
      },
      {
        name: 'Customers',
        data: orderCount2, // Second set of data
        barWidth: "50%",
        type: "line",
      },
    ],
  };



  return (
    <ReactEcharts option={options} style={{ width: "100%" }}></ReactEcharts>
  );
};
