import { useEffect } from "react";
import { useState } from "react";
import ServiceContext from "./ServiceContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";

const ServiceState = (props) => {

  const encKey = process.env.REACT_APP_COMMUNICATION_KEY;

  const navigate = useNavigate();

  const encrypt = (plainText, secretKey) => {
    const cipherText = CryptoJS.AES.encrypt(plainText, secretKey);
    // setEncryptedMessage(cipherText.toString());
    return JSON.stringify({encData: cipherText.toString()})
  }
  const decrypt = (encryptedMessage, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    // setDecryptedMessage(originalText);
    return originalText
  };


  // console.log(encrypt("Hello", encKey))
  // console.log(decrypt("U2FsdGVkX1+sKG8XqzK59JmA/Gpr1xCd4dMxY1owAwo=", encKey))

  function addExternalScript(src, callback) {
    return new Promise((resolve, reject) => {
      var script = document.createElement("script");
      script.src = src;
      script.className = "remove-script";
      script.onload = function () {
        if (typeof callback === "function") {
          callback();
        }
      };
      script.onerror = function () {
        console.error("Error loading script: " + src);
      };
      document.head.appendChild(script);
      resolve(true);
    });
  }

  function showTost(message, type) {
    if (type === "success") {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.info(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "warning") {
      toast.warning(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  // Function to open or create the IndexedDB database
  function openDatabase() {
    const dbName = "devKnox";
    const dbVersion = 1;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("Database error:", event.target.error);
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("tokenStore", { keyPath: "u_id" });
        db.createObjectStore("userStore", { keyPath: "u_id" });
      };
    });
  }

  // Function to add or update the token
  function addOrUpdateToken(token) {
    openDatabase()
      .then((db) => {
        const tokenData = { u_id: 1, token };
        const tokenTransaction = db.transaction(["tokenStore"], "readwrite");
        const tokenStore = tokenTransaction.objectStore("tokenStore");

        tokenStore.put(tokenData);

        tokenTransaction.oncomplete = () => {
          //   console.log("Token added or updated successfully");
          db.close();
        };
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Function to add or update user data
  function addOrUpdateUserData(userData) {
    openDatabase()
      .then((db) => {
        const userTransaction = db.transaction(["userStore"], "readwrite");
        const userStore = userTransaction.objectStore("userStore");
        const userDataUpdated = { ...userData, u_id: 1 };
        userStore.put(userDataUpdated);

        userTransaction.oncomplete = () => {
          //   console.log("User data added or updated successfully");
          db.close();
        };
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Function to read the token and return it
  function readToken() {
    return new Promise((resolve, reject) => {
      openDatabase()
        .then((db) => {
          const tokenTransaction = db.transaction(["tokenStore"], "readonly");
          const tokenStore = tokenTransaction.objectStore("tokenStore");

          tokenStore.get(1).onsuccess = (event) => {
            const tokenData = event.target.result;
            db.close();
            resolve(tokenData ? tokenData.token : null);
          };

          tokenTransaction.onerror = (error) => {
            db.close();
            reject(error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Function to read user data and return it
  function readUserData() {
    return new Promise((resolve, reject) => {
      openDatabase()
        .then((db) => {
          const userTransaction = db.transaction(["userStore"], "readonly");
          const userStore = userTransaction.objectStore("userStore");

          userStore.get(1).onsuccess = (event) => {
            const userData = event.target.result;
            db.close();
            resolve(userData || null);
          };

          userTransaction.onerror = (error) => {
            db.close();
            reject(error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Example usage:
  // readToken().then((token) => {
  //   console.log("Token:", token);
  // }).catch((error) => {
  //   console.error("Error:", error);
  // });

  const [topBar, settopBar] = useState(false);

  const [user, setUser] = useState(true);
  const [currentTheme, setcurrentTheme] = useState(
    localStorage.getItem("phoenixTheme") === "dark" ? true : false
  );
  const [userData, setuserData] = useState({});

  const [dataShare, setDataShare] = useState({
    // All Data
    myName: "ECom",

    // All Default Functions
    onComponentLoad: () => {
      (async () => {
        const removeScripts = document.querySelectorAll(".remove-script");

        removeScripts.forEach((removeScripts) => {
          removeScripts.remove();
        });

        // console.log("Load js file");
        await addExternalScript(
          "vendors/swiper/swiper-bundle.min.js",
          function () {
            // console.log("External script loaded successfully.");
          }
        );
        await addExternalScript("assets/js/phoenix.js", function () {
          // console.log("External script loaded successfully.");
        });
        await addExternalScript(
          "assets/js/ecommerce-dashboard.js",
          function () {
            // console.log("External script loaded successfully.");
          }
        );
        await addExternalScript("assets/js/custom.js", function () {
          // console.log("External script loaded successfully.");
        });
      })();
    },

    handleVToggle: () => {
      let vTogLocal = localStorage.getItem("phoenixIsNavbarVerticalCollapsed");
      if (vTogLocal === "true") {
        document
          .getElementsByTagName("html")[0]
          .classList.remove("navbar-vertical-collapsed");
        localStorage.setItem("phoenixIsNavbarVerticalCollapsed", "false");
      } else {
        document
          .getElementsByTagName("html")[0]
          .classList.add("navbar-vertical-collapsed");
        localStorage.setItem("phoenixIsNavbarVerticalCollapsed", "true");
      }
    },

    signOut: () => {
      console.log("SignedOut.");
      addOrUpdateToken("--");
      navigate("/login");
      setUser(false);
      //   this.isLoggedIn = false

      //   updateToken();
    },

    customSignIn: (email, password) => {
      fetch(`${process.env.REACT_APP_BACKEND}/api/auth/login`, {
        method: "POST",
        body: encrypt(JSON.stringify({
          email: email,
          password: password,
        }), encKey),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.rescode === 0) {
            showTost(data.error, "error");
            // console.log(data.error, "error");
          }

          if (data.rescode === 1) {
            showTost("Login Successful", "success");
            addOrUpdateToken(data.authToken);
            addOrUpdateUserData(data.userData);
            setUser(true);
            navigate("/dash");
          }
          // Handle data
        })
        .catch((err) => {
          console.log(err.message);
          toast(err.message);
        });
    },

    customSignUp: async (fname, lname, email, password) => {
      // console.log(name, email, password, process.env.REACT_APP_BACKEND)
      fetch(`${process.env.REACT_APP_BACKEND}/api/auth/createuser`, {
        method: "POST",
        body: encrypt(JSON.stringify({
          fname: fname,
          lname: lname,
          email: email,
          password: password,
        }), encKey),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.rescode === 0) {
            showTost(data.error, "error");
            // console.log(data.error, "error");
          }

          if (data.rescode === 1) {
            showTost("Your account has been created.", "success");
            navigate("/login");
            setTimeout(() => {
              showTost(
                "We have sent you an email for email verification.",
                "info"
              );
            }, 2000);
          }

          // Handle data
        })
        .catch((err) => {
          console.log(err.message);
          toast(err.message);
        });
    },

    validateToken() {
      // console.log('called')
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/checkauth`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                test: "test",
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.rescode === 0) {
                  // showTost('Invalid Token, Please Login Again', "error");
                  // console.log(data.error, "error");
                  this.isLoggedIn = false;
                  resolve(false);
                  setUser(false);
                }

                if (data.id) {
                  addOrUpdateUserData(data);
                  // console.log(data.id)
                  this.isLoggedIn = true;
                  resolve(true);
                  setUser(true);
                  readUserData()
                    .then((userData) => {
                      // console.log("User data:", userData);
                      setuserData(userData);
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                }

                // Handle data
              })
              .catch((err) => {
                console.log(err.message);
                //   toast(err.message);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    googleLogin: (codeResponse) => {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/auth/googleauth`, {
          method: "POST",
          body: encrypt(JSON.stringify({
            access_data: codeResponse,
            access_of: "gcp",
          }), encKey),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            if (data.rescode === 0) {
              showTost(data.error, "error");
              // console.log(data.error, "error");
              resolve(true);
            }

            if (data.rescode === 1) {
              if (data.userData.newUser) {
                showTost("SignUp Successful", "success");
              } else {
                showTost("Login Successful", "success");
              }

              addOrUpdateToken(data.authToken);
              addOrUpdateUserData(data.userData);
              setUser(true);
              navigate("/dash");
              resolve(true);
            }
          })
          .catch((err) => {
            console.log(err.message);
            toast(err.message);
            resolve(true);
          });
      });
    },

    sendEmailConfirmation() {
      // console.log('called')
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(
              `${process.env.REACT_APP_BACKEND}/api/auth/sendEmailConfirmation`,
              {
                method: "POST",
                body: encrypt(JSON.stringify({
                  test: "test",
                }), encKey),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  authToken: tokenData,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.rescode === 0) {
                  showTost(data.error, "error");
                  // console.log(data.error, "error");
                }

                if (data.rescode === 1) {
                  showTost("Email send.", "success");
                  this.signOut();
                  setTimeout(() => {
                    showTost(
                      "Try to login again after email verification done.",
                      "info"
                    );
                  }, 2000);
                  navigate("/dash");
                }
              })
              .catch((err) => {
                console.log(err.message);
                //   toast(err.message);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserDataUsingToken(token) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/auth/checkauth`, {
          method: "POST",
          body: encrypt(JSON.stringify({
            test: "test",
          }), encKey),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authToken: token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.rescode === 0) {
              showTost("Something went wrong, try again.", "error");
              // console.log(data.error, "error");
              this.signOut();
              resolve(false);
            }

            if (data.id) {
              resolve(data);
            }

            // Handle data
          })
          .catch((err) => {
            console.log(err.message);
            //   toast(err.message);
          });
      });
    },

    confirmEmailNow(token) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updateProfle`, {
          method: "POST",
          body: encrypt(JSON.stringify({
            emailverified: true,
          }), encKey),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authToken: token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.rescode === 0) {
              showTost("Something went wrong, try again.", "error");
              // console.log(data.error, "error");
              this.signOut();
              reject(false);
            }

            if (data.rescode === 1) {
              showTost("Email verified", "success");
              navigate("/login");
              setTimeout(() => {
                showTost("Login and try now.", "info");
              }, 2000);
              resolve(data);
            }
          })
          .catch((err) => {
            console.log(err.message);
            //   toast(err.message);
          });
      });
    },

    showNewTost(mgs, type) {
      showTost(mgs, type);
    },

    facebookLogin: () => {
      console.log("Facebook API Called");
    },

    askForResetPass: (emailId) => {
      // console.log(emailId)
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/auth/resetpassreq`, {
          method: "POST",
          body: encrypt(JSON.stringify({
            email: emailId,
          }), encKey),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.rescode === 0) {
              showTost("Sorry, User with this email is not exist.", "error");
              resolve(false);
            }

            if (data.rescode === 1) {
              showTost("Reset password link send via email.", "success");
              navigate("/login");
              setTimeout(() => {
                showTost("Try to login after password reset", "info");
              }, 2000);
              resolve(true);
            }
          })
          .catch((err) => {
            console.log(err.message);
            //   toast(err.message);
          });
      });
    },

    resertPassNow: (token, pass) => {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/auth/resetpassword`, {
          method: "POST",
          body: encrypt(JSON.stringify({
            password: pass,
          }), encKey),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authToken: token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.rescode === 0) {
              showTost("Something went wrong, try again.", "error");
              // console.log(data.error, "error");
              this.signOut();
              resolve(false);
            }

            if (data.rescode === 1) {
              showTost("Password updated", "success");
              navigate("/login");
              setTimeout(() => {
                showTost("Login and try now.", "info");
              }, 2000);
              resolve(data);
            }
          })
          .catch((err) => {
            console.log(err.message);
            //   toast(err.message);
          });
      });
    },

    updateProfileNow: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updateProfle`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(true);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    printDateBasedOnTP: (tp) => {
      const date = new Date(parseInt(tp));
      return date.toDateString()
    },
    printTimeBasedOnTP: (tp) => {
      const date = new Date(parseInt(tp));
      const hours = date.getHours() % 12 || 12; // Ensure 12-hour format
      const minutes = date.getMinutes();
      const period = date.getHours() < 12 ? 'AM' : 'PM';
    
      // Format the time manually
      const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
      
      return formattedTime.toUpperCase();
    },

    // All Custom Functions
    getListofEmps: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallemps`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateAccess: (newData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updateemp`, {
              method: "POST",
              body: encrypt(JSON.stringify(newData), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createEmp: (newUserData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/createemp`, {
              method: "POST",
              body: encrypt(JSON.stringify(newUserData), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getListofProducts: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallproducts`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addProductNow: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/addproduct`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editProductNow: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/editproduct`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addNewBrand: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/addbrand`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getListofBrands: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallbands`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeBrand: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/removebrand`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getListofCustomers: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallcustomers`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerDetails: (uid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getcustomerdetails`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                id: uid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerOrderDetails: (uid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getcustomerdetails-order`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: uid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerWishlistDetails: (uid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getcustomerdetails-wishlist`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: uid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerCartListDetails: (uid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getcustomerdetails-cart`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: uid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


    getAllOrderDetails: (uid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallorders`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: uid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrderDetails: (oid) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getorderdata`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                oid: oid,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    encodeToUrlFriendly: (inputString) => {
      return encodeURIComponent(inputString);
    },

    decodeFromUrlFriendly: (encodedString) => {
      return decodeURIComponent(encodedString);
    },

    updateOrderStatus: (oid, odata) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updateorderdata`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                oid: oid,
                odata: odata
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    formatAmount(number) {
      // Convert the number to a string and split it into integer and decimal parts
      number = parseInt(number);
      const parts = number.toFixed(2).toString().split(".");
      // Add commas to the integer part
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Join the integer and decimal parts back together
      const formattedAmount = parts.join(".");
      return formattedAmount;
    },

    addNewCoupon: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/addcoupon`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getListofCoupons: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallcoupons`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeCoupon: (filterData) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/removecoupon`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                filter: filterData,
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getBannerData: (data) =>{
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getbanners`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateBanners: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updatebanners`, {
              method: "POST",
              body: data,
              headers: {
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateCustomerCouponData: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/updatecoupondatacustomer`, {
              method: "POST",
              body: encrypt(JSON.stringify(data), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getHomeDash: (data) => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/gethomedash`, {
              method: "POST",
              body: encrypt(JSON.stringify(data), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    
    formatTime(time) {
      const hours = time.getHours();
      const minutes = String(time.getMinutes()).padStart(2, "0");
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      
      return `${formattedHours}:${minutes} ${ampm}`;
    },

    getListofCustomersForDownload: () => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallcustomersexport`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                data: "no Data"
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


    getListofOrdersForDownload: () => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallordersexport`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                data: "no Data"
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getListofProductsForDownload: () => {
      settopBar(true);
      return new Promise((resolve, reject) => {
        readToken()
          .then((tokenData) => {
            fetch(`${process.env.REACT_APP_BACKEND}/api/auth/getallproductsexport`, {
              method: "POST",
              body: encrypt(JSON.stringify({
                data: "no Data"
              }), encKey),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                authToken: tokenData,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Handle the response from the backend
                settopBar(false);
                resolve(data);
              })
              .catch((error) => {
                settopBar(false);
                resolve(false);
                console.error("Error :", error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    



  });

  useEffect(() => {
    if (currentTheme) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [currentTheme]);

  return (
    <ServiceContext.Provider
      value={{
        dataShare,
        setDataShare,
        user,
        currentTheme,
        setcurrentTheme,
        userData,
        topBar,
        settopBar,
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  );
};

export default ServiceState;
