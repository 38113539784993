import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ServiceContext from "../services/ServiceContext";
import { useGoogleLogin } from "@react-oauth/google";
import { MainLogo } from "../dash/InnerComps/Logos/MainLogo";

export const SingUp = () => {
  const { dataShare, settopBar } = useContext(ServiceContext);

  const customSignUp = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    // const regName = /^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$/;
    const regEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    let formIsValid = true;
    let invalidFields = [];

    if (formFieldData.fname.length < 2) {
      formIsValid = false;
      invalidFields.push("fname");
    }
    if (formFieldData.lname.length < 2) {
      formIsValid = false;
      invalidFields.push("lname");
    }
    if (!regEmail.test(formFieldData.email)) {
      formIsValid = false;
      invalidFields.push("email");
    }
    if (
      formFieldData.password !== "" &&
      formFieldData.confirmPassword !== "" &&
      formFieldData.password === formFieldData.confirmPassword &&
      formFieldData.password.length > 3
    ) {
    } else {
      formIsValid = false;
      invalidFields.push("password");
      invalidFields.push("confirmPassword");
    }
    if (!formFieldData.terms) {
      formIsValid = false;
      invalidFields.push("termsService");
    }

    if (formIsValid) {
      dataShare.customSignUp(
        formFieldData.fname,
        formFieldData.lname,
        formFieldData.email,
        formFieldData.password
      );
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      settopBar(true);
      dataShare.googleLogin(codeResponse).then(() => {
        settopBar(false);
      });
    },
  });
  // const facebookLogin = ()=>{
  //   dataShare.facebookLogin()
  // }

  const defaultValues = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: true,
  };
  const [formFieldData, setformFieldData] = useState(defaultValues);

  return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
                <Link
                  className="d-flex flex-center text-decoration-none mb-4"
                  to="/"
                >
                  <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                    <MainLogo type={"main"} customWidth={"150"} />
                    {/* <img
                      src="../../../assets/img/icons/logo.png"
                      alt="phoenix"
                      width={58}
                    /> */}
                  </div>
                </Link>
                <div className="text-center mb-7">
                  <h3 className="text-1000">Sign Up</h3>
                  <p className="text-700">Create your account today</p>
                </div>
                {/* <button className="btn btn-phoenix-secondary w-100 mb-3" onClick={googleLogin}>
                  <span className="fab fa-google text-danger me-2 fs--1" />
                  Sign up with google
                </button>
                <button className="btn btn-phoenix-secondary w-100" onClick={facebookLogin}>
                  <span className="fab fa-facebook text-primary me-2 fs--1" />
                  Sign up with facebook
                </button>
                <div className="position-relative mt-4">
                  <hr className="bg-200" />
                  <div className="divider-content-center">or use email</div>
                </div> */}
                <form>
                  <div className="mb-3 text-start">
                    <label className="form-label" htmlFor="name">
                      First Name
                    </label>
                    <input
                      className="form-control formField"
                      id="fname"
                      type="text"
                      placeholder="First Name"
                      value={formFieldData.fname}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          fname: e.target.value,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <label className="form-label" htmlFor="name">
                      Last Name
                    </label>
                    <input
                      className="form-control formField"
                      id="lname"
                      type="text"
                      placeholder="Last Name"
                      value={formFieldData.lname}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          lname: e.target.value,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <label className="form-label" htmlFor="email">
                      Email address
                    </label>
                    <input
                      className="form-control formField"
                      id="email"
                      type="email"
                      placeholder="name@example.com"
                      value={formFieldData.email}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          email: e.target.value,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                    />
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col-md-6">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        className="form-control form-icon-input formField"
                        id="password"
                        type="password"
                        placeholder="Password"
                        value={formFieldData.password}
                        onChange={(e) => {
                          setformFieldData({
                            ...formFieldData,
                            password: e.target.value,
                          });
                          e.target.classList.remove("is-invalid");
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label" htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      <input
                        className="form-control form-icon-input formField"
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        value={formFieldData.confirmPassword}
                        onChange={(e) => {
                          setformFieldData({
                            ...formFieldData,
                            confirmPassword: e.target.value,
                          });
                          e.target.classList.remove("is-invalid");
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input formField"
                      id="termsService"
                      type="checkbox"
                      checked={formFieldData.terms}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          terms: !formFieldData.terms,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                    />
                    <label
                      className="form-label fs--1 text-none"
                      htmlFor="termsService"
                    >
                      I accept the <a href="sign-up.html#!">terms </a>and{" "}
                      <a href="sign-up.html#!">privacy policy</a>
                    </label>
                  </div>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    type="button"
                    onClick={customSignUp}
                  >
                    Sign up
                  </button>
                  <div className="text-center">
                    <Link className="fs--1 fw-bold" to="/login">
                      Sign in to an existing account
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {useEffect(() => {
        dataShare.onComponentLoad();
      }, [])}
    </>
  );
};
