import React from "react";
import ReactEcharts from "echarts-for-react";

export const TotalSolds = (props) => {
  // console.log(props.data)
  let label = props.lable;
  let dates = [];
  let orderCount = [];
  props.data.map((odata, id) => {
    dates.push(odata.prdName);
    orderCount.push(odata.prdSolds);
  });

  const options = {
    // ECharts options
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      data: dates,
      axisLabel: {
        width: 80, //fixed number of pixels
        overflow: "truncate", // or 'break' to continue in a new line
        interval: 0,
        rotate: 0,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: label,
        visible: false,
        data: orderCount,
        barWidth: "30%",
        type: "bar",
      },
    ],
  };

  return (
    <ReactEcharts option={options} style={{ width: "100%" }}></ReactEcharts>
  );
};
