import React, { useContext } from "react";
import ServiceContext from "../../../services/ServiceContext";

export const MainLogo = ({ type, customWidth }) => {
  const { dataShare, currentTheme } = useContext(ServiceContext);
  let mainWidth = customWidth;
  if (type == "main" && currentTheme == true) {
    return (
      <img
        src="../../../assets/img/custom/MainWhite.png"
        alt="phoenix"
        width={mainWidth}
      />
    );
  } else if (type == "main" && currentTheme == false) {
    return (
      <img
        src="../../../assets/img/custom/MainBlack.png"
        alt="phoenix"
        width={mainWidth}
      />
    );
  }
  else if (type == "invoice") {
    return (
      <img
        src="../../../assets/img/custom/MainBlack.png"
        alt="phoenix"
        width={mainWidth}
      />
    );
  }
};
