import React from "react";
// import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";

export const MothSpecificGraph = (props) => {
   // console.log(props.data)
   let label = props.lable
   let dates = [];
   let orderCount = [];
   props.data.map((odata, id) => {
     dates.push(odata.date);
     orderCount.push(odata.count);
   });
 
  //  console.log(dates);
  //  console.log(orderCount);
   const options = {
     // ECharts options
     tooltip: {
       trigger: "axis",
       axisPointer: {
         type: "cross",
       },
     },
     xAxis: {
       data: dates,
    //    axisLabel: {
    //      width: 80, //fixed number of pixels
    //      overflow: "truncate", // or 'break' to continue in a new line
    //      interval: 0,
    //      rotate: 40,
    //    },
     },
     yAxis: {
       type: "value",
       minInterval: 1,
       splitLine: {
         show: true,
         opacity:0
       }
     },
     series: [
       {
        name: label,
         visible: false,
         data: orderCount,
         barWidth: "50%",
         type: "line",
         
       },
     ],
   };
 
   return (
     <ReactEcharts option={options} style={{ width: "100%" }}></ReactEcharts>
   );
}
