import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import * as XLSX from "xlsx";
import { Pagination } from "../CRM/Pagination";

export const ListProducts = () => {
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allProductsData, setallProductsData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");
  const callCountLimit = 10;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: "",
    limit: callCountLimit,
    skip: 0,
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    dataShare.getListofProducts(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        setallProductsData([]);
      } else {
        setallProductsData(data.allDBProducts);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter]);

  const downloadExcel = () => {
    dataShare.getListofProductsForDownload().then((res) => {
      if (res.rescode) {

        let data = res.data;
        // console.log(data)

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

        let d = new Date();
        let date = dataShare.formatDate(d);
        let time = dataShare.formatTime(d);
        let fileName = `All Products ${date}-${time}.xlsx`;
        // Triggering the download
        XLSX.writeFile(workbook, fileName);
      }else{
        dataShare.showNewTost("Something Went Wrong", "error  ")
      }
    });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Products</li>
          </ol>
        </nav>
        <div className="row g-2 mb-4">
          <div className="d-sm-flex justify-content-between">
            <h2 className="mb-0">Products</h2>
            <div className="d-flex mb-0">
              <button
                className="btn btn-phoenix-primary me-0 px-6"
                onClick={downloadExcel}
              >
                Export All &nbsp;
                <span className="fas fa-file-export"></span>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <input
                  className="form-control search-input search"
                  type="search"
                  placeholder="Search product"
                  aria-label="Search"
                  value={callDataFilter.filter}
                  onChange={(e) => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filter: e.target.value,
                      skip: 0,
                    });
                    setcallCountData({
                      ...callCountData,
                      activePage: 0,
                    });
                  }}
                />
                <span className="fas fa-search search-box-icon" />
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <Link to="/dash/addproduct">
                  <button className="btn btn-primary">
                    <span className="fas fa-plus me-2" />
                    Add product
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs--1 mb-0">
                <thead>
                  <tr>
                    <th
                      className="price align-middle"
                      scope="col"
                      data-sort="customer"
                      // style={{ width: "15%", minWidth: 200 }}
                    >
                      ID
                    </th>
                    <th
                      className="align-middle "
                      scope="col"
                      data-sort="customer"
                      // style={{ width: "15%", minWidth: 200 }}
                    >
                      Image
                    </th>
                    <th
                      className="align-middle ps-4"
                      scope="col"
                      data-sort="customer"
                    >
                      Product Name
                    </th>
                    <th
                      className="price align-right ps-4"
                      scope="col"
                      data-sort="email"
                      style={{
                        textalign: "right",
                      }}
                    >
                      MRP
                    </th>
                    <th
                      className="price align-right ps-4"
                      scope="col"
                      data-sort="mobile_number"
                      style={{
                        textalign: "right",
                      }}
                    >
                      SP
                    </th>
                    <th
                      className="price align-right ps-4"
                      scope="col"
                      data-sort="mobile_number"
                      style={{
                        textalign: "right",
                      }}
                    >
                      Stock
                    </th>
                    <th
                      className="price align-right ps-4"
                      scope="col"
                      data-sort="mobile_number"
                      style={{
                        textalign: "right",
                      }}
                    >
                      Sold
                    </th>
                    <th
                      className="sort align-middle ps-4"
                      scope="col"
                      data-sort="last_active"
                      // style={{ width: "21%", minWidth: 200 }}
                    >
                      Gender Category
                    </th>
                    <th
                      className="sort align-middle ps-4"
                      scope="col"
                      data-sort="city"
                      // style={{ width: "10%" }}
                    >
                      Band
                    </th>
                    <th
                      className="sort align-middle ps-4"
                      scope="col"
                      data-sort="city"
                      // style={{ width: "10%" }}
                    >
                      Sizes
                    </th>
                    <th
                      className="align-middle ps-4"
                      scope="col"
                      data-sort="joined"
                      // style={{ width: "19%", minWidth: 200 }}
                    >
                      Age Group
                    </th>
                    <th
                      className="sort align-middle text-end pe-0"
                      scope="col"
                      data-sort="joined"
                      // style={{ width: "19%", minWidth: 200 }}
                    >
                      Added / Modified On
                    </th>
                    <th
                      className="sort align-middle text-end pe-0"
                      scope="col"
                      data-sort="joined"
                      // style={{ width: "19%", minWidth: 200 }}
                    >
                      Product Status
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {allProductsData.map(function (p, idx) {
                    return (
                      <tr className="position-static" key={idx}>
                        <td className="price align-middle ">{idx + 1}</td>
                        <td className="align-middle ">
                          {p.prdImages.map((pImg, id) => {
                            if (pImg.feature) {
                              return (
                                <Link
                                  key={id}
                                  className="fw-semi-bold line-clamp-3 mb-0"
                                  to={`/dash/products/${p._id}`}
                                >
                                  <img src={pImg.w50} alt="" width={40} />
                                </Link>
                              );
                            }
                          })}
                        </td>
                        <td className="align-middle ps-4">
                          <Link
                            className="fw-semi-bold line-clamp-3 mb-0"
                            to={`/dash/products/${p._id}`}
                          >
                            {p.prdName}
                          </Link>
                        </td>
                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          ₹{p.prdMRP}
                        </td>
                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          ₹{p.prdSP}
                        </td>
                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          {p.prdQty}
                        </td>
                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          {p.prdSolds}
                        </td>
                        <td className="category align-middle white-space-nowrap text-600 fs--1 ps-4 fw-semi-bold">
                          {p.prdGender.toUpperCase()}
                        </td>
                        <td className="category align-middle white-space-nowrap text-600 fs--1 ps-4 fw-semi-bold">
                          {p.prdBrand.toUpperCase()}
                        </td>

                        <td className="tags align-middle review pb-2 ps-3">
                          {p.prdSize.map(function (size, id) {
                            return (
                              <span className="badge badge-tag me-2 " key={id}>
                                {size}
                              </span>
                            );
                          })}
                        </td>
                        <td className="tags align-middle review pb-2 ps-3">
                          {p.prdForAge.map(function (age, id) {
                            return (
                              <span className="badge badge-tag me-2 " key={id}>
                                {age}
                              </span>
                            );
                          })}
                        </td>

                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          {dataShare.printDateBasedOnTP(p.prdEditedOn) || "NA"}
                        </td>

                        <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                          {p.prdPublished ? (
                            <span className="badge badge-phoenix badge-phoenix-success me-2">
                              Published
                            </span>
                          ) : (
                            <span className="badge badge-phoenix badge-phoenix-warning me-2">
                              Draft
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Pagination 
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
              />
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        dataShare.onComponentLoad();
        if (
          userData.accessAllowed.admin ||
          userData.accessAllowed.productManager
        ) {
        } else {
          dataShare.showNewTost(
            "Invalid access for Product Management.",
            "error"
          );
          navigate("/dash");
        }
      }, [])}
    </>
  );
};
