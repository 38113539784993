import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { Pagination } from "./Pagination";

export const CustomerInnterCart = (props) => {
  // console.log(props.uid)
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allCustomData, setallCustomData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");
  const callCountLimit = 4;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: props.uid,
    limit: callCountLimit,
    skip: 0,
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    dataShare.getCustomerCartListDetails(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        setallCustomData([]);
      } else {
        setallCustomData(data.customData);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter]);
  return (
    <>
      <div className="border-top border-bottom border-200">
        {allCustomData ? (
          <>
            <div className="table-responsive scrollbar">
              <div
                className="border-200 border-top border-bottom"
              >
                <div className="table-responsive scrollbar">
                  <table className="table fs--1 mb-0">
                    <thead>
                      <tr>
                        <th
                          className="sort white-space-nowrap align-middle fs--2"
                          scope="col"
                          style={{ width: "5%" }}
                        />
                        <th
                          className="sort white-space-nowrap align-middle"
                          scope="col"
                          style={{ width: "15%", minWidth: 250 }}
                          data-sort="products"
                        >
                          PRODUCTS
                        </th>
                        <th
                          className="sort white-space-nowrap align-middle"
                          scope="col"
                          style={{ width: "10%", minWidth: "10%" }}
                          data-sort="products"
                        >
                          COLOR
                        </th>
                        <th
                          className="sort white-space-nowrap align-middle"
                          scope="col"
                          style={{ width: "10%", minWidth: "10%"  }}
                          data-sort="products"
                        >
                          AGE GROUP
                        </th>
                        <th
                          className="sort white-space-nowrap align-middle"
                          scope="col"
                          style={{ width: "10%", minWidth: "10%" }}
                          data-sort="products"
                        >
                          SIZE
                        </th>
                        <th
                          className="sort align-middle text-end"
                          scope="col"
                          data-sort="price"
                          style={{ width: "10%" }}
                        >
                          PRICE
                        </th>
                        <th
                          className="sort align-middle text-end"
                          scope="col"
                          data-sort="price"
                          style={{ width: "10%" }}
                        >
                          QTY
                        </th>
                        <th
                          className="sort align-middle text-end"
                          scope="col"
                          data-sort="price"
                          style={{ width: "10%" }}
                        >
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list" id="customer-wishlist-table-body">
                      {allCustomData.map((d, idx) => {
                        let featuredImg = d.prdImages.find(
                          (i) => i.feature === true
                        );
                        return (
                          <tr
                            className="hover-actions-trigger btn-reveal-trigger position-static"
                            key={idx}
                          >
                            <td className="align-middle white-space-nowrap py-1">
                              <div className="border rounded-2 d-inline-block">
                                <img
                                  src={featuredImg.w50}
                                  alt=""
                                  width={40}
                                  height={40}
                                />
                              </div>
                            </td>
                            <td className="products align-middle">
                              <Link
                                className="fw-semi-bold mb-0"
                                to={"/dash/products/"+ d._id}
                              >
                                {d.prdName}
                              </Link>
                            </td>
                            <td className="products align-middle">
                              <span
                                className="fw-semi-bold mb-0"
                              >
                                {d.prdData.prdColor}
                              </span>
                            </td>
                            <td className="products align-middle">
                              <span
                                className="fw-semi-bold mb-0"
                              >
                                {d.prdData.prdForAge}
                              </span>
                            </td>
                            <td className="products align-middle">
                              <span
                                className="fw-semi-bold mb-0"
                              >
                                {d.prdData.prdSize}
                              </span>
                            </td>
                            <td className="total align-middle fw-bold text-1000 text-end">
                              <span
                                className="fw-semi-bold mb-0"
                                href="customer-details.html#!"
                              >
                                {d.prdData.qty}
                              </span>
                            </td>
                            <td className="total align-middle fw-bold text-1000 text-end">
                              ₹{dataShare.formatAmount(d.prdSP)}
                            </td>
                            <td className="total align-middle fw-bold text-1000 text-end">
                               <b> ₹{dataShare.formatAmount(parseInt(d.prdData.qty)* parseInt(d.prdSP))}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <Pagination
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
              />
      </div>
    </>
  );
}
