import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashFooter } from "../CommonSections/DashFooter";
import ServiceContext from "../../../services/ServiceContext";
import * as XLSX from "xlsx";
import { Pagination } from "./Pagination";
import { PaymentStatusCondition } from "./InternalBlocks/PaymentStatusCondition";
import { OrderStatusCondition } from "./InternalBlocks/OrderStatusCondition";

export const OrderList = () => {
  let urlSearchString = window.location.search;
  let params = new URLSearchParams(urlSearchString);
  const [filtertype, setfiltertype] = useState(params.get("filtertype"));

  const [filterChangeCount, setfilterChangeCount] = useState(0);

  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allCustomData, setallCustomData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");
  const callCountLimit = 10;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: "",
    limit: callCountLimit,
    skip: 0,
    filtertype: filtertype || "",
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    // setfiltertype(params.get("filtertype"));
    dataShare.getAllOrderDetails(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        setallCustomData([]);
      } else {
        setallCustomData(data.customData);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter, filtertype]);

  const downloadExcel = () => {
    dataShare.getListofOrdersForDownload().then((res) => {
      if (res.rescode) {
        let data = res.data;
        // console.log(data)

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

        let d = new Date();
        let date = dataShare.formatDate(d);
        let time = dataShare.formatTime(d);
        let fileName = `All Orders ${date}-${time}.xlsx`;
        // Triggering the download
        XLSX.writeFile(workbook, fileName);
      } else {
        dataShare.showNewTost("Something Went Wrong", "error  ");
      }
    });
  };

  return (
    <>
      <div className="content">
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dash">Dash</Link>
            </li>
            <li className="breadcrumb-item active">Orders</li>
          </ol>
        </nav>
        <div className="mb-9">
          <div className="row g-2 mb-4">
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-0">Orders</h2>
              <div className="d-flex mb-0">
                <button
                  className="btn btn-phoenix-primary me-2 px-6"
                  onClick={downloadExcel}
                >
                  Export All &nbsp;
                  <span className="fas fa-file-export"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-links mb-3 mb-lg-2 mx-n3">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "",
                    });
                  }}
                >
                  All
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders?filtertype=inprogress"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "inprogress",
                    });
                  }}
                >
                  In Progress Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders?filtertype=delivered"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "delivered",
                    });
                  }}
                >
                  Delivered Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders?filtertype=refund"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "refund",
                    });
                  }}
                >
                  Refunded Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders?filtertype=canceled"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "canceled",
                    });
                  }}
                >
                  Cancelled Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dash/orders?filtertype=pendding"
                  onClick={() => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filtertype: "pendding",
                    });
                  }}
                >
                  Pending Orders
                </Link>
              </li>
            </ul>
          </div>

          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <input
                  className="form-control search-input search"
                  type="search"
                  placeholder="Search Order"
                  aria-label="Search"
                  value={callDataFilter.filter}
                  onChange={(e) => {
                    setcallDataFilter({
                      ...callDataFilter,
                      filter: e.target.value,
                      skip: 0,
                    });
                    setcallCountData({
                      ...callCountData,
                      activePage: 0,
                    });
                  }}
                />
                <span className="fas fa-search search-box-icon" />
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
            {allCustomData ? (
              <>
                <div className="table-responsive scrollbar">
                  <table className="table table-sm fs--1 mb-0">
                    <thead>
                      <tr>
                        <th
                          className="sort white-space-nowrap align-middle ps-0 pe-3"
                          scope="col"
                          data-sort="order"
                          style={{ width: "10%" }}
                        >
                          ORDERS
                        </th>
                        <th
                          className="sort align-middle text-end pe-7"
                          scope="col"
                          data-sort="total"
                          style={{ width: "15%" }}
                        >
                          TOTAL
                        </th>
                        <th
                          className="sort align-middle white-space-nowrap pe-3"
                          scope="col"
                          data-sort="payment_status"
                          style={{ width: "10%" }}
                        >
                          PAYMENT STATUS
                        </th>
                        <th
                          className="sort align-middle white-space-nowrap text-start pe-3"
                          scope="col"
                          data-sort="fulfilment_status"
                          style={{ width: "15%" }}
                        >
                          FULFILMENT STATUS
                        </th>
                        <th
                          className="sort align-middle white-space-nowrap text-start pe-3"
                          scope="col"
                          data-sort="fulfilment_status"
                          style={{ width: "20%" }}
                        >
                          Modified By
                        </th>
                        <th
                          className="sort align-middle white-space-nowrap text-start pe-3"
                          scope="col"
                          data-sort="fulfilment_status"
                          style={{ width: "13%" }}
                        >
                          Modified On
                        </th>

                        <th
                          className="sort align-middle text-end pe-0"
                          scope="col"
                          data-sort="date"
                        >
                          Ordered On DATE
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list" id="customer-order-table-body">
                      {allCustomData.map((d, idx) => {
                        return (
                          <tr
                            className="hover-actions-trigger btn-reveal-trigger position-static"
                            key={idx}
                          >
                            <td className="order align-middle white-space-nowrap ps-0">
                              <Link className="fw-semi-bold" to={d._id}>
                                {d.OrderId}
                              </Link>
                            </td>
                            <td className="total align-middle text-end fw-semi-bold pe-7 text-1000">
                              ₹{dataShare.formatAmount(d.OrderAmount)}
                            </td>


                            <td className="payment_status align-middle white-space-nowrap text-start fw-bold text-700">
                              <PaymentStatusCondition
                                status={d.paymentStatus}
                              />
                            </td>
                            <td className="fulfilment_status align-middle white-space-nowrap text-start fw-bold text-700">
                              <OrderStatusCondition status={d.orderStatus} />
                            </td>

                            <td className="date align-middle white-space-nowrap text-700 fs--1 ">
                              {d.orderModifiedBy || "N/A"}
                            </td>
                            <td className="date align-middle white-space-nowrap text-700 fs--1 ">
                              {dataShare.printDateBasedOnTP(
                                d.orderModifiedOn || d.orderCrearedOn
                              ) +
                                " - " +
                                dataShare.printTimeBasedOnTP(
                                  d.orderModifiedOn || d.orderCrearedOn
                                )}
                            </td>
                            <td className="date align-middle white-space-nowrap text-700 fs--1 ps-4 text-end">
                              {dataShare.printDateBasedOnTP(d.orderCrearedOn) +
                                " - " +
                                dataShare.printTimeBasedOnTP(d.orderCrearedOn)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}

            <Pagination
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
            />
          </div>
        </div>
        <DashFooter />
      </div>

      {useEffect(() => {
        dataShare.onComponentLoad();
        if (userData.accessAllowed.admin || userData.accessAllowed.crmManager) {
        } else {
          dataShare.showNewTost("Invalid access for CRM Management.", "error");
          navigate("/dash");
        }
      }, [])}
    </>
  );
};
