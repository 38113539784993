import React from "react";

export const Pagination = ({
  callCountData,
  callDataFilter,
  setcallCountData,
  paginationLoop,
  setcallDataFilter,
}) => {
  return (
    <div className="row align-items-center justify-content-between py-2 pe-0 fs--1">
      <div className="col-auto d-flex"></div>
      {callCountData.pages > 1 ? (
        <div className="col-auto d-flex">
          <button
            className={
              callCountData.activePage == 0 ? "page-link disabled" : "page-link"
            }
            onClick={(e) => {
              setcallDataFilter({
                ...callDataFilter,
                skip: callDataFilter.skip - 1,
              });

              setcallCountData({
                ...callCountData,
                activePage: callCountData.activePage - 1,
              });
            }}
          >
            <span className="fas fa-chevron-left" />
          </button>

          <ul className="mb-0 pagination">
            {paginationLoop.length < 6 ? (
              <>
                {paginationLoop.map((num, i) => {
                  //   console.log();
                  let currentPageNumber =
                    parseInt(callCountData.activePage) + 1;
                  let previousPageNumber = currentPageNumber - 1;
                  let nextPageNumber = currentPageNumber + 1;

                  //   console.log(
                  //     "Small ",
                  //     previousPageNumber,
                  //     currentPageNumber,
                  //     nextPageNumber,
                  //     paginationLoop.length
                  //   );

                  return (
                    <InnerCompMe
                      callCountData={callCountData}
                      i={i}
                      setcallDataFilter={setcallDataFilter}
                      callDataFilter={callDataFilter}
                      setcallCountData={setcallCountData}
                      key={i}
                      maxPageLimit={paginationLoop.length}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {paginationLoop.map((num, i) => {
                  let currentPageNumber =
                    parseInt(callCountData.activePage) + 1;
                  let previousPageNumber = currentPageNumber - 1;
                  let nextPageNumber = currentPageNumber + 1;

                  //   console.log(
                  //     "large",
                  //     previousPageNumber,
                  //     currentPageNumber,
                  //     nextPageNumber,
                  //     paginationLoop.length
                  //   );

                  let currentIndex = parseInt(i) + 1;

                  if (currentPageNumber == 1) {
                    if (
                      currentIndex == previousPageNumber ||
                      currentIndex == currentPageNumber ||
                      currentIndex == nextPageNumber ||
                      currentIndex == paginationLoop.length ||
                      currentIndex == nextPageNumber + 1
                    ) {
                      return (
                        <InnerCompMe
                          callCountData={callCountData}
                          i={i}
                          setcallDataFilter={setcallDataFilter}
                          callDataFilter={callDataFilter}
                          setcallCountData={setcallCountData}
                          key={i}
                          maxPageLimit={paginationLoop.length}
                        />
                      );
                    }
                  } else if (currentPageNumber == paginationLoop.length) {
                    if (
                      currentIndex == previousPageNumber ||
                      currentIndex == currentPageNumber ||
                      currentIndex == nextPageNumber ||
                      currentIndex == 1 ||
                      currentIndex == previousPageNumber - 1
                    ) {
                      return (
                        <InnerCompMe
                          callCountData={callCountData}
                          i={i}
                          setcallDataFilter={setcallDataFilter}
                          callDataFilter={callDataFilter}
                          setcallCountData={setcallCountData}
                          key={i}
                          maxPageLimit={paginationLoop.length}
                        />
                      );
                    }
                  } else {
                    if (currentPageNumber == paginationLoop.length - 1) {
                      if (
                        currentIndex == previousPageNumber ||
                        currentIndex == currentPageNumber ||
                        currentIndex == nextPageNumber ||
                        currentIndex == 1
                      ) {
                        return (
                          <InnerCompMe
                            callCountData={callCountData}
                            i={i}
                            setcallDataFilter={setcallDataFilter}
                            callDataFilter={callDataFilter}
                            setcallCountData={setcallCountData}
                            key={i}
                            maxPageLimit={paginationLoop.length}
                          />
                        );
                      }
                    } else if (currentPageNumber == 2) {
                      if (
                        currentIndex == previousPageNumber ||
                        currentIndex == currentPageNumber ||
                        currentIndex == nextPageNumber ||
                        currentIndex == paginationLoop.length
                      ) {
                        return (
                          <InnerCompMe
                            callCountData={callCountData}
                            i={i}
                            setcallDataFilter={setcallDataFilter}
                            callDataFilter={callDataFilter}
                            setcallCountData={setcallCountData}
                            key={i}
                            maxPageLimit={paginationLoop.length}
                          />
                        );
                      }
                    } else {
                      if (
                        currentIndex == previousPageNumber ||
                        currentIndex == currentPageNumber ||
                        currentIndex == nextPageNumber ||
                        currentIndex == 1 ||
                        currentIndex == paginationLoop.length
                      ) {
                        return (
                          <InnerCompMe
                            callCountData={callCountData}
                            i={i}
                            setcallDataFilter={setcallDataFilter}
                            callDataFilter={callDataFilter}
                            setcallCountData={setcallCountData}
                            key={i}
                            maxPageLimit={paginationLoop.length}
                          />
                        );
                      }
                    }
                  }
                })}
              </>
            )}
          </ul>
          <button
            className={
              callCountData.activePage + 1 == callCountData.pages
                ? "page-link disabled"
                : "page-link"
            }
            onClick={(e) => {
              setcallDataFilter({
                ...callDataFilter,
                skip: callDataFilter.skip + 1,
              });

              setcallCountData({
                ...callCountData,
                activePage: callCountData.activePage + 1,
              });
            }}
          >
            <span className="fas fa-chevron-right" />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const InnerCompMe = ({
  callCountData,
  i,
  setcallDataFilter,
  callDataFilter,
  setcallCountData,
  maxPageLimit,
}) => {
  let classNameList = "page-item";

  if (callCountData.activePage == i) {
    classNameList = classNameList + " active";
  }

  if(maxPageLimit > 4){
    if (parseInt(i) + 1 == 1) {
        classNameList = classNameList + " highlited";
      }
      if (parseInt(i) + 1 == maxPageLimit) {
        classNameList = classNameList + " highlited";
      }
  }

  
  return (
    <li className={classNameList} key={i}>
      <span
        className="page-link page"
        type="button"
        onClick={(e) => {
          setcallDataFilter({
            ...callDataFilter,
            skip: i,
          });

          setcallCountData({
            ...callCountData,
            activePage: i,
          });
        }}
      >
        {i + 1}
      </span>
    </li>
  );
};
