import React, { useContext, useEffect, useState } from "react";
import ServiceContext from "../../../services/ServiceContext";
import { DashFooter } from "../CommonSections/DashFooter";
import { ImageUploadSection } from "./ImageUploadSection";
import { data } from "jquery";

export const Banners = () => {
  const { dataShare, userData } = useContext(ServiceContext);
  let bannerData = {
    UIKV: "KVS",
    Banner1: {
      enable: false,
      desktop:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      mobile:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      redirectURL: false,
    },
    Banner2: {
      enable: false,
      desktop:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      mobile:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      redirectURL: false,
    },
    Banner3: {
      enable: false,
      desktop:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      mobile:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      redirectURL: false,
    },
    Banner4: {
      enable: false,
      video: "0M9AaFGVt_k",
    },
    Banner5: {
      enable: false,
      desktop:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      mobile:
        process.env.REACT_APP_STATIC_BASE_UI + "assets/img/generic/59.png",
      redirectURL: false,
    },
  };

  const [kvData, setkvData] = useState(bannerData);

  const [uploadData, setuploadData] = useState([]);

  const [updateChanges, setupdateChanges] = useState(0);

  useEffect(() => {
    setkvData(bannerData);
    dataShare.getBannerData("").then((data) => {
      if (data.rescode == 1) {
        if (data.bannerData) {
          setkvData(data.bannerData);
        }
      } else {
        setkvData(bannerData);
      }
    });
  }, [updateChanges]);

  const setNewImgData = (data) => {
    let arrayOfObjects = uploadData;
    function updateOrAddObject(newObject) {
      // newObject.imageFile.name = newObject.imageID
      // Check if the array contains an object with the specified imageID
      const existingObject = arrayOfObjects.find(
        (obj) => obj.imageID === newObject.imageID
      );
      // If an object with the specified imageID is found, update it
      if (existingObject) {
        existingObject.imageFile = newObject.imageFile;
      } else {
        // If not found, add a new object to the array
        arrayOfObjects.push(newObject);
      }
    }
    updateOrAddObject(data);
    setuploadData(arrayOfObjects);
    // console.log(uploadData)
  };

  const resetBanner = () => {
    //   setkvData(bannerData);
    setuploadData([]);
    setupdateChanges(updateChanges + 1);
  };

  const submitNewData = () => {
    console.log(uploadData);

    const FD = new FormData();
    FD.append("id", userData.id);
    FD.append("fileType", "banner");
    let fileOrders = [];
    if (uploadData) {
      for (const file of uploadData) {
        FD.append("files", file.imageFile);
        fileOrders.push(file.imageID);
      }
    }
    FD.append("fileOrders", JSON.stringify(fileOrders));
    FD.append("bannerData", JSON.stringify(kvData));

    dataShare.updateBanners(FD).then((data) => {
      if (data.rescode == 1) {
        if (data.bannerData) {
          console.log(111);
          setkvData(data.bannerData);
          setupdateChanges(updateChanges + 1);
        }
      }
    });
  };

  return (
    <>
      <div className="content ">
        {kvData ? (
          <>
            <div className="row mb-10">
              <div className="col-12">
                {/* {kvData.Banner1.enable.toString()} */}
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="kv1"
                    type="checkbox"
                    //value={true}
                    checked={kvData?.Banner1?.enable}
                    // defaultChecked={kvData.Banner1.enable}
                    onChange={() => {
                      setkvData((prevData) => ({
                        ...prevData,
                        Banner1: {
                          ...prevData.Banner1,
                          enable: !kvData.Banner1.enable,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="kv1">
                    Main Banner Section
                  </label>
                </div>
              </div>
              {kvData.Banner1.enable ? (
                <div className="col-12 row">
                  <div className="col-6 col-sm-3">
                    <h4>Desktop</h4>
                    <ImageUploadSection
                      imgId="kv1-d"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                  <div className="col-6 col-sm-3">
                    <h4>Mobile</h4>
                    <ImageUploadSection
                      imgId="kv1-m"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="row mb-10">
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="kv2"
                    type="checkbox"
                    //value={true}
                    checked={kvData?.Banner2?.enable}
                    // defaultChecked={kvData.Banner2.enable}
                    onChange={() => {
                      setkvData((prevData) => ({
                        ...prevData,
                        Banner2: {
                          ...prevData.Banner2,
                          enable: !kvData.Banner2.enable,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="kv2">
                    1st Side Banner Section
                  </label>
                </div>
              </div>
              {kvData.Banner2.enable ? (
                <div className="col-12 row">
                  <div className="col-6 col-sm-3">
                    <h4>Desktop</h4>
                    <ImageUploadSection
                      imgId="kv2-d"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                  <div className="col-6 col-sm-3">
                    <h4>Mobile</h4>
                    <ImageUploadSection
                      imgId="kv2-m"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="row mb-10">
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="kv3"
                    type="checkbox"
                    //value={true}
                    checked={kvData?.Banner3?.enable}
                    // defaultChecked={kvData.Banner3.enable}
                    onChange={() => {
                      setkvData((prevData) => ({
                        ...prevData,
                        Banner3: {
                          ...prevData.Banner3,
                          enable: !kvData.Banner3.enable,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="kv3">
                    2nd Side Banner Section
                  </label>
                </div>
              </div>
              {kvData.Banner3.enable ? (
                <div className="col-12 row">
                  <div className="col-6 col-sm-3">
                    <h4>Desktop</h4>
                    <ImageUploadSection
                      imgId="kv3-d"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                  <div className="col-6 col-sm-3">
                    <h4>Mobile</h4>
                    <ImageUploadSection
                      imgId="kv3-m"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="row mb-10">
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="kv4"
                    type="checkbox"
                    //value={true}
                    checked={kvData?.Banner4?.enable}
                    // defaultChecked={kvData.Banner3.enable}
                    onChange={() => {
                      setkvData((prevData) => ({
                        ...prevData,
                        Banner4: {
                          ...prevData.Banner4,
                          enable: !kvData.Banner4.enable,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="kv4">
                    YouTube Video
                  </label>
                </div>
              </div>
              {kvData.Banner4.enable ? (
                <div className="col-12 row">
                  <div className="col-6 col-sm-3">
                    <h4>Youtube Video</h4>
                    <input
                      className="form-control mb-10"
                      type="text"
                      placeholder="Video ID"
                      value={kvData?.Banner4?.video}
                      onChange={(e) => {
                        setkvData((prevData) => ({
                          ...prevData,
                          Banner4: {
                            ...prevData.Banner4,
                            video: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="row mb-10">
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    id="kv5"
                    type="checkbox"
                    //value={true}
                    checked={kvData?.Banner5?.enable}
                    // defaultChecked={kvData.Banner3.enable}
                    onChange={() => {
                      setkvData((prevData) => ({
                        ...prevData,
                        Banner5: {
                          ...prevData.Banner5,
                          enable: !kvData.Banner5.enable,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="kv5">
                    Advertisement Section
                  </label>
                </div>
              </div>
              {kvData.Banner5.enable ? (
                <div className="col-12 row">
                  <div className="col-6 col-sm-3">
                    <h4>Desktop</h4>
                    <ImageUploadSection
                      imgId="kv5-d"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                  <div className="col-6 col-sm-3">
                    <h4>Mobile</h4>
                    <ImageUploadSection
                      imgId="kv5-m"
                      kvData={kvData}
                      setuploadData={setuploadData}
                      setNewImgData={setNewImgData}
                      uploadData={uploadData}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="row">
              <div className="col-xl-9">
                <div className="d-sm-flex justify-content-between">
                  <h2 className="mb-4">&nbsp;</h2>
                  <div className="d-flex mb-3">
                    <button
                      className="btn btn-phoenix-primary me-2 px-6"
                      onClick={resetBanner}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary px-6"
                      onClick={submitNewData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
