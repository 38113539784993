import React from "react";
import { Link } from "react-router-dom";
import { MainLogo } from "../Logos/MainLogo";
export const Invoice = (props) => {
  let allProductsData = props.allProductsData;
  let dataShare = props.dataShare;
  // let showInvoice = props.showInvoice
  // let setshowInvoice = props.setshowInvoice
  const print = () => document.print();

  let forDownload = props.forDownload;

  return (
    <div className="invoice_p">
      {forDownload ? (
        <div className="bg-soft dark__bg-1100 p-4 mb-4 rounded-2 invoice_p pad15 overfh">
          <div className="w_100 parent_inv">
            <div className="col-lg-4 cell_33">
              <div className="w_100 mb-4">
                <div className="cell_33">
                  <h6 className="mb-0 me-3">Invoice No (m) :</h6>
                </div>
                <div className="overfh">
                  <h6 className="fs--1 text-800 fw-semi-bold mb-0">
                    #{allProductsData._id}
                  </h6>
                </div>
              </div>
              <div className="w_100">
                <div className="cell_33">
                  <h6 className="mb-0 me-3">Invoice Date :</h6>
                </div>
                <div className="overfh">
                  <h6 className="fs--1 text-800 fw-semi-bold mb-0">
                    {dataShare.printDateBasedOnTP(
                      allProductsData.paymentUpdatedOn
                    )}
                  </h6>
                </div>
              </div>
              {/* <div className="">
                <div className="col-12 col-lg-12">
                  <div className="">
                    <div className="cell_50">
                      <h6 className="mb-0 me-3">Invoice No (m) :</h6>
                    </div>
                    <div className="cell_50">
                      <p className="fs--1 text-800 fw-semi-bold mb-0">
                        #{allProductsData._id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-12">
                  <div className="row align-items-center g-0">
                    <div className="col-auto col-lg-6 col-xl-5">
                      <h6 className="me-3">Invoice Date :</h6>
                    </div>
                    <div className="col-auto col-lg-6 col-xl-7">
                      <p className="fs--1 text-800 fw-semi-bold mb-0">
                        {dataShare.printDateBasedOnTP(
                          allProductsData.paymentUpdatedOn
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-4 cell_33">
              <div className="row g-4 gy-lg-5">
                <div className="col-12 col-lg-8">
                  <h6 className="mb-2 me-3">Sold by :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    {dataShare.myName}
                    <br />
                    <>== Company Addr===</>
                  </p>
                </div>
                <div className="cell_50">
                  <h6 className="mb-2"> PAN No :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    <>== Company Pan===</>
                  </p>
                </div>
                <div className="cell_50">
                  <h6 className="mb-2"> Order Id :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    {allProductsData.OrderId}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 cell_33">
              <div className="row g-0">
                <div className="col-12 col-lg-12">
                  <h6 className="mb-2"> Shipping Address :</h6>
                  <div className="fs--1 text-800 fw-semi-bold mb-0">
                    <p className="mb-2">{allProductsData.ShippingUserName}</p>
                    <p className="mb-2">
                      {allProductsData.ShippingAddr.addrLine1},{" "}
                      {allProductsData.ShippingAddr.addrLine2},{" "}
                      {allProductsData.ShippingAddr.city},{" "}
                      {allProductsData.ShippingAddr.state},{" "}
                      {allProductsData.ShippingAddr.zip}
                    </p>
                    <p className="mb-2">{allProductsData.ShippingEmail}</p>
                    <p className="mb-0">{allProductsData.ShippingPhone}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-soft dark__bg-1100 p-4 mb-4 rounded-2 invoice_p">
          <div className="row g-4">
            <div className="col-12 col-lg-4">
              <div className="row g-4 g-lg-2">
                <div className="col-12 col-sm-6 col-lg-12">
                  <div className="row align-items-center g-0">
                    <div className="col-auto col-lg-6 col-xl-5">
                      <h6 className="mb-0 me-3">Invoice No :</h6>
                    </div>
                    <div className="col-auto col-lg-6 col-xl-7">
                      <p className="fs--1 text-800 fw-semi-bold mb-0">
                        #{allProductsData._id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-12">
                  <div className="row align-items-center g-0">
                    <div className="col-auto col-lg-6 col-xl-5">
                      <h6 className="me-3">Invoice Date :</h6>
                    </div>
                    <div className="col-auto col-lg-6 col-xl-7">
                      <p className="fs--1 text-800 fw-semi-bold mb-0">
                        {dataShare.printDateBasedOnTP(
                          allProductsData.paymentUpdatedOn
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="row g-4 gy-lg-5">
                <div className="col-12 col-lg-8">
                  <h6 className="mb-2 me-3">Sold by :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    {dataShare.myName}
                    <br />
                    <>== Company Addr===</>
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <h6 className="mb-2"> PAN No :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    <>== Company Pan===</>
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <h6 className="mb-2"> Order Id :</h6>
                  <p className="fs--1 text-800 fw-semi-bold mb-0">
                    {allProductsData.OrderId}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="row g-0">
                <div className="col-12 col-lg-12">
                  <h6 className="mb-2"> Shipping Address :</h6>
                  <div className="fs--1 text-800 fw-semi-bold mb-0">
                    <p className="mb-2">{allProductsData.ShippingUserName}</p>
                    <p className="mb-2">
                      {allProductsData.ShippingAddr.addrLine1},{" "}
                      {allProductsData.ShippingAddr.addrLine2},{" "}
                      {allProductsData.ShippingAddr.city},{" "}
                      {allProductsData.ShippingAddr.state},{" "}
                      {allProductsData.ShippingAddr.zip}
                    </p>
                    <p className="mb-2">{allProductsData.ShippingEmail}</p>
                    <p className="mb-0">{allProductsData.ShippingPhone}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="px-0">
        <div className="table-responsive scrollbar">
          <table className="table fs--1 text-900 mb-0">
            <thead className="bg-200">
              <tr>
                <th scope="col" style={{ width: 24 }} />
                <th scope="col" style={{ minWidth: 60 }}>
                  SL NO.
                </th>
                <th scope="col" style={{ minWidth: 360 }}>
                  Products
                </th>
                <th className="ps-5" scope="col" style={{ minWidth: 100 }}>
                  Color
                </th>
                <th className="ps-5" scope="col" style={{ minWidth: 100 }}>
                  Size
                </th>
                <th
                  className="ps-5"
                  scope="col"
                  style={{ minWidth: 150 }}
                  colSpan={2}
                >
                  Age Grp
                </th>

                <th className="text-end" scope="col" style={{ width: 80 }}>
                  Quantity
                </th>
                <th className="text-end" scope="col" style={{ width: 100 }}>
                  Price
                </th>
                <th
                  className="text-end"
                  scope="col"
                  style={{ minWidth: 60 }}
                  colSpan="2"
                >
                  Total
                </th>
                <th scope="col" style={{ width: 24 }} />
              </tr>
            </thead>
            <tbody>
              {allProductsData.OrderCartData.map((d, idx) => {
                return (
                  <tr key={idx}>
                    <td className="border-0" />
                    <td className="align-middle">{idx + 1}</td>
                    <td className="align-middle">
                      <p className="line-clamp-1 mb-0 fw-semi-bold">
                        {d.prdName}
                      </p>
                    </td>
                    <td className="align-middle ps-5">{d.prdColor}</td>
                    <td className="align-middle ps-5">{d.prdSize}</td>
                    <td className="align-middle ps-5" colSpan={2}>
                      {d.prdForAge}
                    </td>
                    <td className="align-middle text-end ps-5 fw-semi-bold">
                      {d.qty}
                    </td>
                    <td className="align-middle text-end fw-semi-bold">
                      ₹{d.prdSP}
                    </td>
                    <td
                      className="align-middle text-end fw-semi-bold"
                      colSpan="2"
                    >
                      ₹{dataShare.formatAmount(d.prdSP * d.qty)}
                    </td>
                    <td className="border-0" />
                  </tr>
                );
              })}

              <tr className="bg-200">
                <td />
                <td className="align-middle fw-semi-bold" colSpan={9}>
                  Subtotal
                </td>
                <td className="align-middle text-end fw-bold">
                  ₹{dataShare.formatAmount(allProductsData.cartData.finalPrice)}
                </td>
                <td />
              </tr>
              <tr>
                <td className="border-0" />
                <td colSpan={6} />
                <td className="align-middle fw-bold ps-0" colSpan={2}>
                  Tax ({allProductsData.cartData.taxPercent})
                </td>
                <td className="align-middle text-end fw-semi-bold" colSpan={2}>
                  + ₹{dataShare.formatAmount(allProductsData.cartData.taxAmt)}
                </td>
                <td className="border-0" />
              </tr>

              {allProductsData.cartData.couponCode ? (
                <tr>
                  <td className="border-0" />
                  <td colSpan={6} />
                  <td className="align-middle fw-bold ps-0" colSpan={2}>
                    <span className="text-danger">
                      Discount ({allProductsData.cartData.getDiscountPercent}%)
                    </span>
                  </td>
                  <td
                    className="align-middle text-end fw-semi-bold"
                    colSpan={2}
                  >
                    <span className="text-danger">
                      - ₹
                      {dataShare.formatAmount(
                        allProductsData.cartData.discountAmount
                      )}
                    </span>
                  </td>
                  <td className="border-0" />
                </tr>
              ) : (
                <></>
              )}

              <tr>
                <td className="border-0" />
                <td colSpan={6} />
                <td className="align-middle fw-bold ps-0" colSpan={2}>
                  Shipping Cost
                </td>
                <td className="align-middle text-end fw-semi-bold" colSpan={2}>
                  + ₹
                  {dataShare.formatAmount(
                    allProductsData.cartData.shippingCost
                  )}
                </td>
                <td className="border-0" />
              </tr>

              <tr className="bg-200">
                <td className="align-middle ps-4 fw-bold text-1000" colSpan={3}>
                  Grand Total
                </td>
                <td className="align-middle fw-bold text-1000" colSpan={7}>
                  {/* Three Hundred and Ninenty Eight USD */}
                </td>
                <td className="align-middle text-end fw-bold">
                  ₹{dataShare.formatAmount(allProductsData.cartData.grandTotal)}
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-end py-9 border-bottom border-300 p-5">
          <div className="mb-3 me-6">
          <MainLogo type={"invoice"} className="" customWidth={120}/>
          </div>
          <h4>Authorized Signatory</h4>
        </div>
        <div className="text-center py-4 mb-9">
          <p className="mb-0 bill_last_line">Thank you for buying with us.
          {/* {dataShare.myName} */}
          </p>
        </div>
      </div>
    </div>
  );
};
