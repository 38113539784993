import React, { useState, useEffect } from "react";
import { useContext } from "react";
import ServiceContext from "../services/ServiceContext";
import { useParams, useNavigate } from "react-router-dom";

export const ConfirmEmail = () => {
  const { dataShare } = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  let { authToken } = useParams();
  const navigate = useNavigate();

  const [tempUserData, settempUserData] = useState({});
  useEffect(() => {
    (() => {
      dataShare
      .getUserDataUsingToken(authToken)
      .then((data) => {
        setIsLoading(false); // Set loading to false when promise resolves
        if (data) {
          settempUserData(data);
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        //   toast(err.message);
      });
    })();
    
  }, []);
  if (isLoading) {
    // Render a loading indicator or any other content while waiting for the promise
    return <div>Loading...</div>;
  }

  const confirmEmailNow = () => {
    if (!tempUserData.emailverified) {
      dataShare
        .confirmEmailNow(authToken)
        .then((data) => {
          if (data) {
            settempUserData(data);
            console.log(data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      navigate("/login");
      dataShare.showNewTost("Email is already verified.", "info");
    }
  };

  return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
                <div className="text-center mb-5">
                  <div className="avatar avatar-4xl mb-4">
                    <img
                      className="rounded-circle"
                      src={
                        tempUserData.photoURL ||
                        process.env.REACT_APP_USER_PROFILE
                      }
                      alt=""
                    />
                  </div>

                  <h2 className="text-1000">
                    {" "}
                    <span className="fw-normal">Hello </span>
                    {tempUserData.fname} {tempUserData.lname}
                  </h2>
                  <br />
                  <p className="text-700">
                    To confirm your email on{" "}
                    <b>{process.env.REACT_APP_INDEXDB}</b> <br />
                    click below confirm button.
                  </p>
                </div>

                <button
                  className="btn btn-primary w-100"
                  onClick={confirmEmailNow}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
