import React from "react";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ServiceContext from "../services/ServiceContext";
import { useGoogleLogin } from "@react-oauth/google";
import { MainLogo } from "../dash/InnerComps/Logos/MainLogo";

export const LogIn = () => {
  const { dataShare, settopBar } = useContext(ServiceContext);

  const customSignIn = () => {
    const allElements = document.querySelectorAll(".formField");
    allElements.forEach((element) => {
      element.classList.remove("is-invalid");
    });

    // const regName = /^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$/;
    const regEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    let formIsValid = true;
    let invalidFields = [];

    if (!regEmail.test(formFieldData.email)) {
      formIsValid = false;
      invalidFields.push("cemail");
    }
    if (formFieldData.password && formFieldData.password.length > 3) {
    } else {
      formIsValid = false;
      invalidFields.push("cpassword");
    }

    if (formIsValid) {
      dataShare.customSignIn(formFieldData.email, formFieldData.password);
    } else {
      invalidFields.forEach((e) => {
        document.getElementById(e).classList.add("is-invalid");
      });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      settopBar(true);
      dataShare.googleLogin(codeResponse).then(() => {
        settopBar(false);
      });
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      customSignIn();
    }
  };

  // email: "shekhar.chavan25121996@gmail.com",
  const defaultValues = {
    email: "",
    password: "",
  };
  const [formFieldData, setformFieldData] = useState(defaultValues);

  return (
    <>
      <main className="main" id="top">
        <div className="container-fluid px-0" data-layout="container">
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
                <Link
                  className="d-flex flex-center text-decoration-none mb-4"
                  to="/"
                >
                  <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                    <MainLogo type={"main"} customWidth={"150"} />
                    {/* <img
                      src="../../../assets/img/icons/logo.png"
                      alt="phoenix"
                      width={58}
                    /> */}
                  </div>
                </Link>

                <div className="text-center mb-7">
                  <h3 className="text-1000">Sign In</h3>
                  <p className="text-700">Get access to your account</p>
                </div>

                {/* <button
                  className="btn btn-phoenix-secondary w-100 mb-3"
                  onClick={googleLogin}
                >
                  <span className="fab fa-google text-danger me-2 fs--1" />
                  Sign in with google
                </button>
                <button
                  className="btn btn-phoenix-secondary w-100"
                  onClick={facebookLogin}
                >
                  <span className="fab fa-facebook text-primary me-2 fs--1" />
                  Sign in with facebook
                </button> 
                <div className="position-relative">
                  <hr className="bg-200 mt-5 mb-4" />
                  <div className="divider-content-center">
                    <Link to="/dash">or use email</Link>
                  </div>
                </div>*/}
                <div className="mb-3 text-start">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <div className="form-icon-container">
                    <input
                      className="form-control form-icon-input formField"
                      id="cemail"
                      type="email"
                      placeholder="name@example.com"
                      value={formFieldData.email}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          email: e.target.value,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                    />
                    <span className="fas fa-user text-900 fs--1 form-icon" />
                  </div>
                </div>
                <div className="mb-3 text-start">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="form-icon-container">
                    <input
                      className="form-control form-icon-input formField"
                      id="cpassword"
                      type="password"
                      placeholder="Password"
                      value={formFieldData.password}
                      onChange={(e) => {
                        setformFieldData({
                          ...formFieldData,
                          password: e.target.value,
                        });
                        e.target.classList.remove("is-invalid");
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <span className="fas fa-key text-900 fs--1 form-icon" />
                  </div>
                </div>
                <div className="row flex-between-center mb-7">
                  <div className="col-auto">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        id="basic-checkbox"
                        type="checkbox"
                        defaultChecked="checked"
                      />
                      <label
                        className="form-check-label mb-0"
                        htmlFor="basic-checkbox"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <Link className="fs--1 fw-semi-bold" to="/resetpassauth">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <button
                  className="btn btn-primary w-100 mb-3"
                  type="button"
                  onClick={customSignIn}
                >
                  Sign In
                </button>
                <div className="text-center">
                  <Link className="fs--1 fw-bold" to="/signup">
                    Create an account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
