import React, { useContext } from "react";
import ServiceContext from "../services/ServiceContext";
import { NavLink } from "react-router-dom";

export const Sidenav = () => {
  const { dataShare, userData } = useContext(ServiceContext);
  const callSignOut = () => {
    dataShare.signOut();
  };

  return (
    <nav className="navbar navbar-vertical navbar-expand-lg">
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        {/* scrollbar removed*/}
        <div className="navbar-vertical-content">
          <ul className="navbar-nav flex-column" id="navbarVerticalNav">
            <li className="nav-item">
              {/* parent pages*/}

              <div className="nav-item-wrapper">
                <NavLink
                  className="nav-link label-1"
                  to="/dash"
                  end
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <span data-feather="pie-chart" />
                    </span>
                    <span className="nav-link-text-wrapper">
                      <span className="nav-link-text">Home</span>
                    </span>
                  </div>
                </NavLink>
              </div>

              {userData.accessAllowed?.admin ||
              userData.accessAllowed?.employeeManager ? (
                <div className="nav-item-wrapper">
                  <a
                    className="nav-link dropdown-indicator label-1"
                    href="index.html#employeessection"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="employeessection"
                  >
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon">
                        <span className="fas fa-caret-right" />
                      </div>
                      <span className="nav-link-icon">
                        <span data-feather="users" />
                      </span>
                      <span className="nav-link-text">Employees</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul
                      className="nav collapse parent show"
                      data-bs-parent="#navbarVerticalCollapse"
                      id="employeessection"
                    >
                      <li className="collapsed-nav-item-title d-none">
                        Employess
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/addemployee"
                          end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Add Employee</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/employees"
                          data-bs-toggle=""
                          aria-expanded="true"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">View / Edit</span>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {userData.accessAllowed?.admin ||
              userData.accessAllowed?.productManager ? (
                <div className="nav-item-wrapper">
                  <a
                    className="nav-link dropdown-indicator label-1"
                    href="index.html#productssection"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="productssection"
                  >
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon">
                        <span className="fas fa-caret-right" />
                      </div>
                      <span className="nav-link-icon">
                        <span data-feather="shopping-cart" />
                      </span>

                      <span className="nav-link-text">Products</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul
                      className="nav collapse parent show"
                      data-bs-parent="#navbarVerticalCollapse"
                      id="productssection"
                    >
                      <li className="collapsed-nav-item-title d-none">
                      Products
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/addproduct"
                          end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Add Product</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/products"
                          data-bs-toggle="" 
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">View / Edit</span>
                          </div>
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to="/dash/coupons"
                          end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Manage Coupons</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/brands"
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Manage Brands</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/banners"
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Manage KVs</span>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {userData.accessAllowed?.admin ||
              userData.accessAllowed?.crmManager ? (
                <div className="nav-item-wrapper">
                  <a
                    className="nav-link dropdown-indicator label-1"
                    href="index.html#crmsection"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls="crmsection"
                  >
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon">
                        <span className="fas fa-caret-right" />
                      </div>
                      <span className="nav-link-icon">
                        <span data-feather="phone" />
                      </span>

                      <span className="nav-link-text">CRM Management</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul
                      className="nav collapse parent show"
                      data-bs-parent="#navbarVerticalCollapse"
                      id="crmsection"
                    >
                      <li className="collapsed-nav-item-title d-none">
                      CRM Management
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/users"
                          end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Manage Customers</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          to="/dash/orders" end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">All Orders</span>
                          </div>
                        </NavLink>
                      </li>

                      {/* <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          exect={true}
                          to="/dash/orders?inprogress" end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Inprogress Orders</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          exect={true}
                          to="/dash/orders?deliverd" end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Delivered Orders</span>
                          </div>
                        </NavLink>
                      </li>

                      <li className="nav-item nav-hide-mob">
                        <NavLink
                          className="nav-link"
                          exect={true}
                          to="/dash/orders?refund" end
                          data-bs-toggle=""
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <span className="nav-link-text">Refunded Orders</span>
                          </div>
                        </NavLink>
                      </li> */}
                      
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="nav-item-wrapper">
                <NavLink
                  className="nav-link label-1"
                  to="/dash/profile"
                  end
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <span data-feather="user" />
                    </span>
                    <span className="nav-link-text-wrapper">
                      <span className="nav-link-text">Profile</span>
                    </span>
                  </div>
                </NavLink>
              </div>
                <hr/>
              <div className="nav-item-wrapper c_pointer">
                <span
                  className="nav-link label-1"
                  onClick={callSignOut}
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <span data-feather="log-out" />
                    </span>
                    <span className="nav-link-text-wrapper">
                      <span className="nav-link-text">SignOut</span>
                    </span>
                  </div>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-vertical-footer">
        <button
          className="btn border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
          onClick={dataShare.handleVToggle}
        >
          {/* navbar-vertical-toggle  */}
          <span className="uil uil-left-arrow-to-left fs-0" />
          <span className="uil uil-arrow-from-right fs-0" />
          <span className="navbar-vertical-footer-text ms-2">
            Collapsed View
          </span>
        </button>
      </div>
    </nav>
  );
};
