import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ServiceContext from "../../../services/ServiceContext";
import { Pagination } from "./Pagination";
import { PaymentStatusCondition } from "./InternalBlocks/PaymentStatusCondition";
import { OrderStatusCondition } from "./InternalBlocks/OrderStatusCondition";

export const CusomerInnerComps = (props) => {
  // console.log(props.uid)
  const navigate = useNavigate();
  const { dataShare, userData } = useContext(ServiceContext);

  const [allCustomData, setallCustomData] = useState([]);

  const [searchFilter, setsearchFilter] = useState("");
  const callCountLimit = 4;
  const [callCountData, setcallCountData] = useState({
    count: 0,
    pages: 0,
    activePage: 0,
  });
  const [callDataFilter, setcallDataFilter] = useState({
    filter: props.uid,
    limit: callCountLimit,
    skip: 0,
  });
  const [paginationLoop, setPaginationLoop] = useState([]);
  useEffect(() => {
    let numbers = [];
    for (let i = 1; i <= callCountData.pages; i++) {
      numbers.push(i);
    }
    setPaginationLoop(numbers);
  }, [callCountData]);

  useEffect(() => {
    dataShare.getCustomerOrderDetails(callDataFilter).then((data) => {
      if (data.rescode == 0) {
        setallCustomData([]);
      } else {
        setallCustomData(data.customData);
        let cCount = data.count;
        let setPagesCount = 0;
        if (data.count > callCountLimit) {
          let a = data.count / callCountLimit;
          let b = Math.trunc(data.count / callCountLimit);
          if (a > b) {
            a = b + 1;
          }
          setPagesCount = a;
        } else {
          setPagesCount = 0;
        }
        setcallCountData({
          ...callCountData,
          count: data.count,
          pages: setPagesCount,
        });
      }
    });
  }, [callDataFilter]);
  return (
    <>
      <div
        className="border-top border-bottom border-200"
        
      >
        {allCustomData ? (
          <>
            <div className="table-responsive scrollbar">
              <table className="table table-sm fs--1 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort white-space-nowrap align-middle ps-0 pe-3"
                      scope="col"
                      data-sort="order"
                      style={{ width: "10%" }}
                    >
                      ORDER
                    </th>
                    <th
                      className="sort align-middle text-end pe-7"
                      scope="col"
                      data-sort="total"
                      style={{ width: "25%" }}
                    >
                      TOTAL
                    </th>
                    <th
                      className="sort align-middle white-space-nowrap pe-3"
                      scope="col"
                      data-sort="payment_status"
                      style={{ width: "15%" }}
                    >
                      PAYMENT STATUS
                    </th>
                    <th
                      className="sort align-middle white-space-nowrap text-start pe-3"
                      scope="col"
                      data-sort="fulfilment_status"
                      style={{ width: "35%" }}
                    >
                      FULFILMENT STATUS
                    </th>
                    <th
                      className="sort align-middle text-end pe-0"
                      scope="col"
                      data-sort="date"
                    >
                      DATE
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="customer-order-table-body">
                  {allCustomData.map((d, idx) => {
                    return (
                      <tr
                        className="hover-actions-trigger btn-reveal-trigger position-static"
                        key={idx}
                      >
                        <td className="order align-middle white-space-nowrap ps-0">
                          <Link
                            className="fw-semi-bold"
                            to={"/dash/orders/"+d._id}
                          >
                            {d.OrderId}
                          </Link>
                        </td>
                        <td className="total align-middle text-end fw-semi-bold pe-7 text-1000">
                          ₹{dataShare.formatAmount(d.OrderAmount)}
                        </td>
                        <td className="payment_status align-middle white-space-nowrap text-start fw-bold text-700">
                         
                          <PaymentStatusCondition
                                status={d.paymentStatus}
                              />
                        </td>
                        <td className="fulfilment_status align-middle white-space-nowrap text-start fw-bold text-700">
                         
                          <OrderStatusCondition status={d.orderStatus} />
                        </td>
                        <td className="date align-middle white-space-nowrap text-700 fs--1 ps-4 text-end">
                          {dataShare.printDateBasedOnTP(d.orderCrearedOn) +
                            " - " +
                            dataShare.printTimeBasedOnTP(d.orderCrearedOn)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
        <Pagination 
              callCountData={callCountData}
              callDataFilter={callDataFilter}
              setcallCountData={setcallCountData}
              paginationLoop={paginationLoop}
              setcallDataFilter={setcallDataFilter}
              />
      </div>
    </>
  );
};
